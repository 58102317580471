<template>
  <v-card class="card-vacancy">
    <div class="box-title">
      <div class="image">
        <FileImageInstitution :fileId="attributeOffer.volunteering.institution.fileId"
          :fileInstitution="attributeOffer.volunteering.institution" />
      </div>
      <div class="flex-grow-1 display-flex flex-column">
        <div class="title truncate lines-2">{{ attributeOffer.offerName }}</div>
        <small class="truncate-base lines-2">
          por {{ attributeOffer.volunteering.institution.entity.name }}
        </small>
        <div>
          <a @click="goToVolunteering(attributeOffer.volunteeringId, attributeOffer.volunteering.institution.entity.id)"
            class="link">
            <span>Conheça o projeto</span>
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.90625 0.761719L5.71484 0.953125C5.57812 1.08984 5.57812 1.28125 5.71484 1.41797L10.582 6.28516H0.328125C0.136719 6.28516 0 6.44922 0 6.61328V6.88672C0 7.07812 0.136719 7.21484 0.328125 7.21484H10.582L5.71484 12.1094C5.57812 12.2461 5.57812 12.4375 5.71484 12.5742L5.90625 12.7656C6.04297 12.9023 6.23438 12.9023 6.37109 12.7656L12.1406 6.99609C12.2773 6.85938 12.2773 6.66797 12.1406 6.53125L6.37109 0.761719C6.23438 0.625 6.04297 0.625 5.90625 0.761719Z"
                fill="currentColor" />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="card-vacancy-content">
      <div class="display-flex align-center subtitle pb-1">
        <small class="font-weight-bold flex-grow-1">
          {{ attributeOffer.volunteering.fullName }}
        </small>
        <template v-if="attributeOffer.isDistanceOportunityAllowed">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <Icon name="fal fa-globe-americas icon-attribute" v-on="on" />
              </div>
            </template>
            <span>Pode atuar à distância</span>
          </v-tooltip>
        </template>

        <template v-if="attributeOffer.isPresentialOportunityAllowed">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <Icon name="fal fa-users icon-attribute" />
              </div>
            </template>
            <span>Pode atuar presencial</span>
          </v-tooltip>
        </template>

        <template v-if="attributeOffer.volunteeringType.name == 'Pontual'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <Icon name="fal fa-calendar icon-attribute" />
              </div>
            </template>
            <span>Vaga Pontual</span>
          </v-tooltip>
        </template>
        <template v-if="attributeOffer.volunteeringType.name == 'Recorrente'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <Icon name="fal fa-sync icon-attribute" />
              </div>
            </template>
            <span>Vaga Recorrente</span>
          </v-tooltip>
        </template>
      </div>

      <div class="card-vacancy-img" v-if="attributeOffer.file">
        <img :src="attributeOffer.file.path" alt />
      </div>

      <p class="truncate-base lines-5 is-flex-shrink-0 color-theme-texto card-vacancy-description-K_s78">
        {{ attributeOffer.function }}
      </p>


      <div v-if="attributeOffer.volunteeringType.name == 'Recorrente'">

        <template v-for="recurrentType in attributeOffer.volunteeringRecurrentTypes">
          <div class="group_vh75a" :key="recurrentType.id">
            <div class="flex-center">
              <div class="container-icon_vh75a">
                <Icon name="fal fa-sync icon-attribute icon_vh75a" />
              </div>
              <div class="box-hour" :key="recurrentType.id">
                <div class="font-weight-bold color-theme-destaque font-16">
                  {{ getRecurrentWeekHoursText(recurrentType) }}
                </div>
                <div class="font-weight-bold font-20">
                  {{ getRecurrentWeekDayText(recurrentType) }}
                </div>
                <div class="text-light font-14">
                  {{ getRecurrentWeekTimeText(recurrentType) }}
                </div>
              </div>
            </div>


            <div class="ending ml-2_5 container-ending_kw6d" :key="recurrentType.id">
              <Icon name="fal fa-calendar text-light" size="16" class="mr-2" />
              <span class="mr-2 text-light">Termina em:</span>
              <b>{{ getRecurrentLimitDateText(recurrentType) }}</b>
            </div>

          </div>
        </template>
      </div>
      <div v-else-if="attributeOffer.volunteeringType.name == 'Pontual'">
        <div class="hours align-center" v-for="pontualType in attributeOffer.volunteeringPontualTypes"
          :key="pontualType.id">
          <Icon name="fas fa-calendar color-theme-destaque" size="20" />
          <div class="h5 flex-grow-1 font-16">{{ getPontualTypeDateText(pontualType) }}</div>
          <div class="font-14 container-minimal-time">{{ getPontualTypeTimeText(pontualType) }}</div>
        </div>
      </div>
    </div>
    <!-- Multiple subscription option set in true -->
    <div class="display-flex px-4 pb-4" v-if="multipleSlot">
      <v-btn
        v-if="VolunteerSlot.indexOf(attributeOffer.id) === -1 && (attributeOffer.totalOfferAmount - attributeOffer.registrationAmount) > 0"
        color="btn-primary" round dark class="flex-grow-1 mx-0 mt-2" @click="$emit('selectOffer', attributeOffer)">
        Inscreva-se
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn v-else-if="VolunteerSlot.indexOf(attributeOffer.id) !== -1 && (!attributeOffer.isDeleted)"
        color="btn-default" round class="flex-grow-1 mx-0 mt-2" @click="$emit('selectOffer', attributeOffer)">
        Inscrito
        <Icon name="fal fa-check" size="16" class="ml-4" />
      </v-btn>
      <v-btn v-else-if="VolunteerSlot.indexOf(attributeOffer.id) !== -1 && (attributeOffer.isDeleted)"
        color="btn-primary" round dark class="flex-grow-1 mx-0 mt-2"
        @click="$emit('selectOffer', attributeOffer, true)">
        Inscreva-se
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn v-else-if="VolunteerSlot.indexOf(attributeOffer.id) !== -1" color="secondary" round dark
        class="flex-grow-1 mx-0 mt-2" @click="$emit('selectOffer', attributeOffer)">
        Inscrito
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn v-else disabled depressed round class="flex-grow-1 mx-0 mt-2">
        Vagas esgotadas
      </v-btn>
    </div>

    <!-- Multiple subscribe option set to false -->
    <div class="display-flex px-4" :class="avatars.length > 0 ? 'pb-2' : 'pb-5'" v-if="multipleSlot === false">
      <v-btn
        v-if="!hasSubscribedOnVolunteerSlot && ((attributeOffer.totalOfferAmount - attributeOffer.registrationAmount) > 0)"
        color="btn-primary" round dark class="flex-grow-1 mx-0 mt-2" @click="$emit('selectOffer', attributeOffer)">
        Inscreva-se
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn
        v-else-if="hasSubscribedOnVolunteerSlot && (VolunteerSlot.indexOf(attributeOffer.id) !== -1) && (!attributeOffer.isDeleted)"
        color="btn-default" round dark class="flex-grow-1 mx-0 mt-2" @click="$emit('selectOffer', attributeOffer)">
        Inscrito
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn
        v-else-if="hasSubscribedOnVolunteerSlot && (VolunteerSlot.indexOf(attributeOffer.id) === -1) && (attributeOffer.totalOfferAmount - attributeOffer.registrationAmount) !== 0"
        disabled depressed round class="flex-grow-1 mx-0 mt-2">
        Você ja está inscrito em uma vaga
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn v-else-if="(attributeOffer.totalOfferAmount - attributeOffer.registrationAmount) === 0" disabled depressed
        round class="flex-grow-1 mx-0 mt-2">
        Vagas esgotadas
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
    </div>
    <div class="avatar-group">
      <div class="single-avatar tool" v-for="(avatar, index) in avatars.slice(0, 4)" :key="'avatar' + index">
        <img :src="avatar.url" :alt="avatar.name" />
      </div>
      <div class="hidden-avatars" v-if="avatars.length > 3">
        {{ "+" + (avatars.length - 3) }}
      </div>
    </div>



    <div class="card-footer">
      <Icon v-if="attributeOffer.address.city" name="fal fa-map-marker-alt" size="14" class="mr-2 text-light" />
      <div class="flex-grow-1 text-light">
        <span>{{ attributeOffer.address.city }}<template v-if="attributeOffer.address.city">,</template>
          {{ attributeOffer.address.state }}
        </span>
      </div>
      <div class="f-size-13 text-light mr-2 ml-2">Inscritos:</div>
      <b class="color-theme-primaria">
        {{ attributeOffer.registrationAmount }}
      </b><span class="px-1 text-light">|</span>
      <span class="text-light">
        {{
          ((attributeOffer.totalOfferAmount - attributeOffer.registrationAmount) > 0) ? attributeOffer.totalOfferAmount :
            attributeOffer.registrationAmount
        }}
      </span>
    </div>
  </v-card>
</template>

<style>
@import url('~@/font.scss');

* {
  font-family: 'Nunito', sans-serif;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-14 {
  font-size: 14px !important;
}

.title {
  font-family: 'IbmPlex', sans-serif !important;
}

.card-vacancy-description-K_s78 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.container-ending_kw6d{
  background: #F5F6FA;
  padding: 16px;
  border-radius: 8px;
  gap: 10px;
  font-size: 14px !important;
}

.ml-2_5 {
  margin-left: 10px;
}

.container-minimal-time{
  color: #80849A;
  font-weight: bold;
}

.group_vh75a {
  display: flex;
  flex-direction: column;

}

.container-icon_vh75a {
  min-width: 50px;
  min-height: 50px;
  height: 70px;
  width: 70px;
  border-radius: 8px;
  background-color: #FF6516;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}


.flex-center {
  display: flex;
  align-items: center;
  border: 1px solid #E8EAF2;
  border-radius: 12px;
  padding: 16px;
}

.container-icon_vh75a .icon_vh75a {
  position: relative;
  left: -2px;
  font-size: 24px;
}
</style>


<script>
import moment from "moment";
import { Routes } from "@/scripts/models/enums/routes.enum";
import FileImageInstitution from "@/components/FileImageInstitution";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";
import UserService from "@/scripts/services/user.service";
import md5 from "crypto-js/md5"

export default {
  components: {
    FileImageInstitution,
  },
  props: {
    attributeOffer: Object,
    volunteeringAttributeOfferId: Object,
    isSubscribed: Boolean,
    VolunteerSlot: Array,
    hasSubscribedOnVolunteerSlot: Boolean,
    multipleSlot: Boolean,
  },
  data() {
    return {
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      userService: new UserService(),
      volunteeringId: this.volunteeringAttributeOfferId,
      avatars: [],
    };
  },
  async mounted() {
    await this.getAllAvatars(this.volunteeringId);
  },
  methods: {
    getRecurrentWeekHoursText(recurrentType) {
      if (
        recurrentType.minHoursDedication &&
        recurrentType.maxHoursDedication &&
        recurrentType.minHoursDedication != recurrentType.maxHoursDedication
      )
        return `De ${recurrentType.minHoursDedication} a ${recurrentType.maxHoursDedication
          } Horas Semanais`;

      if (recurrentType.minHoursDedication)
        return `${recurrentType.minHoursDedication} Horas Semanais`;

      if (recurrentType.maxHoursDedication)
        return `${recurrentType.maxHoursDedication} Horas Semanais`;

      return "Não definido";
    },
    getRecurrentWeekDayText(recurrentType) {
      var weekDays = [];
      recurrentType.volunteeringRecurrentWeekDays.forEach((weekDay) => {
        if (weekDay.weekDayName == "Segunda") {
          weekDays.push({ order: 1, name: "Seg" });
        } else if (weekDay.weekDayName == "Terça") {
          weekDays.push({ order: 2, name: "Ter" });
        } else if (weekDay.weekDayName == "Quarta") {
          weekDays.push({ order: 3, name: "Quar" });
        } else if (weekDay.weekDayName == "Quinta") {
          weekDays.push({ order: 4, name: "Quinta" });
        } else if (weekDay.weekDayName == "Sexta") {
          weekDays.push({ order: 5, name: "Sex" });
        } else if (weekDay.weekDayName == "Sábado") {
          weekDays.push({ order: 6, name: "Sab" });
        } else if (weekDay.weekDayName == "Domingo") {
          weekDays.push({ order: 7, name: "Dom" });
        }
      });

      if (weekDays.length == 0) return "Não definido";

      weekDays.sort((prev, next) => prev.order - next.order);

      if (this.weekDayHasGaps(weekDays))
        return this.getRecurrentWeekDayTextWithGaps(weekDays);

      return this.getRecurrentWeekDayTextWithoutGaps(weekDays);
    },
    weekDayHasGaps(weekDays) {
      let hasGap = false;
      let index = 0;
      let weekDayBefore = null;
      while (!hasGap && index < weekDays.length) {
        let weekDay = weekDays[index];
        if (weekDayBefore != null && weekDay.order - 1 != weekDayBefore.order)
          hasGap = true;

        weekDayBefore = weekDays[index];
        index++;
      }

      return hasGap;
    },
    getRecurrentWeekDayTextWithGaps(weekDays) {
      let result = "";
      for (let index = 0; index < weekDays.length; index++) {
        const weekDay = weekDays[index];
        result += weekDay.name;

        if (index + 2 == weekDays.length) {
          result += " e ";
        } else if (index + 1 < weekDays.length) {
          result += ", ";
        }
      }
      return result;
    },
    getRecurrentWeekDayTextWithoutGaps(weekDays) {
      if (weekDays.length == 0) return "Não definido";

      if (weekDays.length > 1)
        return `${weekDays[0].name} à ${weekDays[weekDays.length - 1].name}`;

      return `${weekDays[0].name}`;
    },
    getRecurrentWeekTimeText(recurrentType) {
      if (
        recurrentType.convertedInitialTime &&
        recurrentType.convertedFinalTime &&
        recurrentType.convertedInitialTime != recurrentType.convertedFinalTime
      ) {
        return `${recurrentType.convertedInitialTime}h às ${recurrentType.convertedFinalTime}h`;
      }


      if (recurrentType.convertedInitialTime)
        return `às ${recurrentType.convertedInitialTime}h`;
      if (recurrentType.convertedFinalTime)
        return `às ${recurrentType.convertedFinalTime}h`;
    },
    getRecurrentLimitDateText(recurrentType) {
      return moment(recurrentType.volunteeringDateUntil).format("DD/MM/YYYY");
    },
    goToVolunteering(volunteeringId, entityId) {
      this.$router.push({
        name: Routes.app.VolunteeringLP,
        query: { volunteeringId: volunteeringId, entityId: entityId },
      });
    },
    getPontualTypeDateText(pontualType) {
      return moment(pontualType.volunteeringDate).format("DD/MM/YYYY");
    },
    getPontualTypeTimeText(pontualType) {
      if (
        pontualType.initialTime &&
        pontualType.finalTime &&
        pontualType.initialTime != pontualType.finalTime
      )
        return `${moment(pontualType.initialTime).format("HH:mm")}h às ${moment(
          pontualType.finalTime
        ).format("HH:mm")}h`;
      if (pontualType.initialTime)
        return `às ${moment(pontualType.initialTime).format("HH:mm")}h`;
      if (pontualType.finalTime)
        return `às ${moment(pontualType.finalTime).format("HH:mm")}h`;
    },
    async getAllAvatars(AttributeOfferId) {
      const users = []
      const colors = [
        'ff8877', '346dff', '7716ed', '93009e', 'd13b00'
      ]
      const registrations = await this.volunteeringRegistrationOfferService.findAllUsersRegistrationOffersToAttributeOffer(AttributeOfferId)
      let colorIndex = 0
      for (const item of registrations) {
        if (!item.isDeleted) {
          const userInformation = await this.userService.getInformation(item.userId)
          const name = userInformation.name.split(' ').join('+')
          colorIndex++
          let emailHash = md5(userInformation.email);
          const information = {
            id: AttributeOfferId,
            name: userInformation.name,
            url: `https://www.gravatar.com/avatar/${emailHash}?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F${name}/128/${colors[colorIndex]}/ffffff`
          }

          const search = users.some(e => e.name === information.name)
          if (!search) users.push(information)
        }
      }

      this.avatars = users
    },
  },
};
</script>
