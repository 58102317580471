<template>
  <div v-show="canShow">
    <UseTermsModal v-model="showServiceTerms"></UseTermsModal>
    <PrivacyPolicyTermsModal
      v-model="showPolicyTerms"
    ></PrivacyPolicyTermsModal>
    <ModalChatBot
      myMaxWidth="570"
      v-model="showModalChatBot"
      :value="true"
      :cardTitle="false"
      :withClose="true"
    >
      <v-layout flex-column pa-4>
        <v-flex dialog-chatbot>
          <h4>Qual chatbot deseja utilizar?</h4>
          <p>
            Far far away, behind the word mountains, far from the countries
            Vokalia and Consonantia,
          </p>
          <div class="logo-chatbot">
            <div class="mr-4" v-for="bot in chatbots" :key="bot.type">
              <input
                type="radio"
                :id="bot.type"
                :value="bot.type"
                v-model="campaignLandingPageConfig.campaign.chatbotType"
              />
              <label :for="bot.type">
                <div class="b-radius">
                  <img :src="bot.logo" alt="SnatchBot" />
                </div>
                <span class="mt-2">SnatchBot</span>
              </label>
            </div>
            <div class="mr-4">
              <input
                type="radio"
                id="chatbot2"
                value="2"
                v-model="campaignLandingPageConfig.campaign.chatbotType"
              />
              <label for="chatbot2">
                <div class="b-radius">
                  <img src="@/assets/images/icon-drift.png" alt="drift" />
                </div>
                <span class="mt-2">Drift</span>
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="chatbot3"
                value="3"
                v-model="campaignLandingPageConfig.campaign.chatbotType"
              />
              <label for="chatbot3">
                <div class="b-radius">
                  <img src="@/assets/images/icon-manychat.png" alt="manychat" />
                </div>
                <span class="mt-2">manychat</span>
              </label>
            </div>
          </div>
          <InputText
            type="text"
            v-model="campaignLandingPageConfig.campaign.chatbotId"
            place-holder="Key: insira a chave do seu chatbot"
            :focusOnCreate="false"
            labelCharLimit="50"
            invalidInputMessage="Preencha o nome do chatbot"
          />
        </v-flex>
        <v-flex flex pt-3 ml-auto>
          <button class="mr-5 fw-700 t-uppercase">Cancelar</button>
          <button class="fw-700 t-uppercase text-save-chatbot">Salvar</button>
        </v-flex>
      </v-layout>
    </ModalChatBot>
    <!-- <ModalTemplate myMaxWidth="600" v-model="showModalTemplate" :value="true" :cardTitle="false" :withClose="true">
			<v-layout flex-column pa-4>
				<v-flex dialog-chatbot>
					<v-layout flex-column justify-center align-center>
						<v-flex display-flex title-dialog-template>
							Template:
							<h4>Hero</h4>
						</v-flex>
						<v-flex class="display-flex box-template-dialog">
							<div class="box-radio-template">
								<input type="radio" id="teste1" name="teste">
								<label for="teste1">
									<img src="../../../assets/images/thumb_template_padrao.svg" alt="template">
								</label>
							</div>
							<div class="ml-4 box-radio-template">
								<input type="radio" id="teste2" name="teste">
								<label for="teste2">
									<img src="../../../assets/images/tthumb_emplate_img.svg" alt="template">
								</label>
							</div>
						</v-flex>
						<button @click="showModalTemplate=false" class="btn-primary px-5 mt-4 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text">Aplicar</button>
					</v-layout>
				</v-flex>
			</v-layout>
    </ModalTemplate>-->
    <!-- .campanha-preview -->
    <template v-if="editing">
      <ColorPalette
        @fontChanged="changeCSSVariable"
        @colorChanged="changeCSSVariable"
        :colors="themeColors"
        :fontDestaques="campaignLandingPageConfig.fontDestaques"
        :fontPrincipal="campaignLandingPageConfig.fontPrincipal"
      ></ColorPalette>
      <div class="edit-btn">
        <v-btn
          flat
          round
          color="white"
          class="btn-ghost-default mx-0 px-4 font-sys"
          @click="gotoCampaignList()"
          large
        >
          <i class="fas fa-save mr-2 fa-lg"></i> Concluir Edição
        </v-btn>
      </div>

      <!--button code editor-->
      <button class="btn-code-editor" @click="openModalEditor()" type="button">
        <i class="fal fa-code"></i>
      </button>

      <div class="box-btn-campaign">
        <a
          v-if="!campaignLandingPageConfig.showCallToActioOnNav"
          href="javascript:;"
          class="fal fa-eye"
          @click="toggleHiddenClass('showCallToActioOnNav')"
        ></a>
        <a
          v-else
          href="javascript:;"
          class="fal fa-eye-slash"
          @click="toggleHiddenClass('showCallToActioOnNav')"
        ></a>
        <v-btn
          class="btn-campaign"
          :disabled="!campaignLandingPageConfig.showCallToActioOnNav"
        >
          <span
            :contenteditable="editing && !campaignPaused"
            spellcheck="false"
            style="pointer-events: auto"
            @blur="updateProperty('titleCallToActioOnNav', $event)"
            v-html="campaignLandingPageConfig.titleCallToActioOnNav"
          ></span>
        </v-btn>
        <a
          v-if="!campaignLandingPageConfig.showCallToActioOnNav"
          href="javascript:;"
          class="fal fa-eye"
          @click="toggleHiddenClass('showCallToActioOnNav')"
        ></a>
        <a
          v-else
          href="javascript:;"
          class="fal fa-eye-slash"
          @click="toggleHiddenClass('showCallToActioOnNav')"
        ></a>
        <v-btn
          class="btn-campaign"
          :disabled="!campaignLandingPageConfig.showCallToActioOnNav"
        >
          <span
            :contenteditable="editing && !campaignPaused"
            spellcheck="false"
            style="pointer-events: auto"
            @blur="updateProperty('titleCallToActioOnNav', $event)"
            v-html="campaignLandingPageConfig.titleCallToActioOnNav"
          ></span>
        </v-btn>
      </div>

      <css-editor
        :dialog="dialogEditor"
        :cssCode="campaignLandingPageConfig.cssInjection"
        :jsCode="campaignLandingPageConfig.jsInjection"
        @close="closeModalEditor()"
        @apply="applyInjections($event)"
      ></css-editor>
    </template>
    <!-- <div class="donation-potential">
			<span>Seu potencial de doação é:</span>
			<div :title="'Seu potencial de doação é: R$ '+formatterHelper.formatMoney(campaignLandingPageConfig.campaign.capturedValue, 0)">
				R$ {{formatterHelper.formatShortNumber(campaignLandingPageConfig.campaign.capturedValue, 0)}}
				<Icon name="fas fa-calculator-alt" size="16" colorFont="#fff" />
			</div>
    </div>-->
    <InfoProjectCard
      ref="infoProject"
      @simulation="
        landingPageOptions.simulationDrawer = !landingPageOptions.simulationDrawer
      "
    ></InfoProjectCard>
    <section
      class="bloco nav bg-theme-white d-450-none"
      data-title="Barra de Navegação"
      :class="{ hidden: !campaignLandingPageConfig.showMenuBlock }"
      v-if="editing || campaignLandingPageConfig.showMenuBlock"
    >
      <div class="edit" v-if="editing">
        <a
          href="javascript:;"
          class="fal fa-eye-slash"
          @click="toggleHiddenClass('showMenuBlock')"
        ></a>
        <input
          :value="campaignLandingPageConfig.navigationSectionName"
          class="input-title-camp"
          @blur="updatePropertyValue('navigationSectionName', $event)"
        />
      </div>
      <div class="bloco-content">
        <div class="menu-lp-campaign d-none">
          <input type="checkbox" id="menu-campaign" />
          <label for="menu-campaign" class="open-menu-btn"></label>
          <nav class="nav">
            <ul>
              <li
                v-for="anchor in anchors"
                :key="anchor.name"
                v-show="anchor.show"
              >
                <a
                  @click="
                    scrollMeTo(anchor.name);
                    $gtag(
                      'send',
                      'event',
                      anchor.name,
                      anchor.name,
                      anchor.name
                    );
                  "
                  >{{ anchor.text }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>

    <!-- CONTADOR ######################### -->
    <section
      class="contador bloco pa-0"
      style="padding: 0 !important"
      data-title="Barra de Arrecadação"
      :class="{ hidden: !campaignLandingPageConfig.showDonationBarBlock }"
      v-if="
        editing ||
          (campaignLandingPageConfig.showDonationBarBlock &&
            checkProjectType !== ProjectTypes.Voluntariado)
      "
    >
      <div class="edit" v-if="editing">
        <a
          href="javascript:;"
          class="fal fa-eye-slash"
          @click="toggleHiddenClass('showDonationBarBlock')"
        ></a>
        <input
          :value="campaignLandingPageConfig.donationBarSectionName"
          class="input-title-camp"
          @blur="updatePropertyValue('donationBarSectionName', $event)"
        />
      </div>
      <div class="contador">
        <div
          class="arrec"
          :style="{
            width: percentualValue,
          }"
        >
          <small v-if="campaignLandingPageConfig.campaign.id == 266"
            >captado</small
          >
          <small v-else>arrecadado</small>

          <b
            class="notranslate"
            :title="
              `Total arrecadado: ${coinSelected} ${formatterHelper.formatMoney(
                capturedValue,
                0
              )}`
            "
          >
            {{ coinSelected }}
            {{ formatterHelper.formatShortNumber(capturedValue, 2) }}
          </b>
        </div>
        <div class="objet" v-show="capturedValue < objetiveValue">
          <small>objetivo</small>
          <b
            class="notranslate"
            :title="
              'Objetivo: ' +
                coinSelected +
                formatterHelper.formatMoney(objetiveValue, 0)
            "
          >
            {{ coinSelected }}
            {{ formatterHelper.formatShortNumber(objetiveValue, 1) }}
          </b>
        </div>
      </div>
    </section>

    <!-- HERO ######################### -->
    <div class="hero-preview dark bloco p0" ref="heroBlock">
      <!-- <div class="edit">
        <a class="fal fa-cog" @click="showModalTemplate=!showModalTemplate"></a>
      </div>-->
      <div class="wrapper">
        <v-layout row wrap>
          <v-flex preview>
            <!-- Image background -->
            <div
              ref="heroImage"
              class="image-bg"
              :style="{
                backgroundImage: getImage(
                  'heroFile',
                  fileService_renderHeroFileCallback
                ),
                opacity: campaignLandingPageConfig.heroImageOpacity,
              }"
            ></div>
            <!-- https://source.unsplash.com/featured/?landscape -->

            <!-- tools -->
            <div
              class="hero-tools right"
              :class="{
                'preview-off': !heroPreview,
                'preview-on': heroPreview,
              }"
              v-if="editing"
            >
              <!-- image opacity -->
              <div
                class="sub-box slider"
                :class="{ active: showHeroOverlayOpacitySlider }"
              >
                <!--active-->
                <v-slider
                  v-model="campaignLandingPageConfig.heroOverlayOpacity"
                  min="0"
                  max=".8"
                  step="0.05"
                  thumb-label="always"
                  color="#02ACB4"
                  @change="save()"
                ></v-slider>
              </div>
              <!-- overlay opacity -->
              <div
                class="sub-box slider"
                :class="{ active: showHeroImageOpacitySlider }"
              >
                <v-slider
                  v-model="campaignLandingPageConfig.heroImageOpacity"
                  min="0"
                  max="1"
                  step="0.1"
                  thumb-label="always"
                  color="#02ACB4"
                  @change="save()"
                ></v-slider>
              </div>
              <a href="#" @click.prevent="changeHeroAlignment()">
                <i
                  class="fal"
                  :class="{
                    'fa-align-left':
                      campaignLandingPageConfig.heroAlignment == 0,
                    'fa-align-center':
                      campaignLandingPageConfig.heroAlignment == 1,
                    'fa-align-right':
                      campaignLandingPageConfig.heroAlignment == 2,
                  }"
                ></i>
              </a>
              <a
                href="#"
                @click.prevent="toggleHeroPreview()"
                :class="{ active: heroPreview }"
              >
                <i class="fal fa-eye"></i>
              </a>
              <a
                href="#"
                :class="{ active: heroUploadImage }"
                @click.prevent="toggleHeroImageUpload()"
              >
                <i class="fal fa-image"></i>
              </a>
              <!--<a href="#" @click.prevent>
                <i class="fal fa-palette"></i>
              </a>-->
              <a
                href="#"
                @click.prevent="toggleHeroImageOpacity()"
                :class="{ active: showHeroImageOpacitySlider }"
              >
                <i class="custom-icon ci-contrast size-20"></i>
              </a>
              <a
                href="#"
                @click.prevent="toggleHeroOverlayOpacity()"
                :class="{ active: showHeroOverlayOpacitySlider }"
              >
                <i class="custom-icon ci-brightness size-20"></i>
              </a>
            </div>

            <div
              class="text-block"
              :class="{
                'text-left': campaignLandingPageConfig.heroAlignment == 0,
                'text-center': campaignLandingPageConfig.heroAlignment == 1,
                'text-right': campaignLandingPageConfig.heroAlignment == 2,
              }"
            >
              <h1
                :contenteditable="editing"
                spellcheck="true"
                data-title="Título"
                @blur="updateProperty('heroTitle', $event)"
                v-html="campaignLandingPageConfig.heroTitle"
              ></h1>
              <p
                class="mt-3"
                :contenteditable="editing"
                spellcheck="false"
                data-title="Parágrafo"
                @blur="updateProperty('heroParagraph', $event)"
                v-html="campaignLandingPageConfig.heroParagraph"
              ></p>
              <div id="button-on-hero">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-ghost-white mx-0 mt-3"
                  large
                  data-title="Botão"
                  @click="goesTo()"
                  :disabled="campaignPaused || editing || ticketsAvailable"
                >
                  <span
                    :contenteditable="editing && !campaignPaused"
                    spellcheck="false"
                    style="pointer-events: auto"
                    @blur="updateProperty('donationButtonText', $event)"
                    v-html="
                      campaignPaused
                        ? 'Doações Encerradas'
                        : ticketsAvailable
                        ? 'Sem tickets disponíveis'
                        : campaignLandingPageConfig.donationButtonText
                    "
                  ></span>
                </v-btn>
              </div>
              <p
                class="mt-4 notes"
                v-if="checkProjectType !== ProjectTypes.Voluntariado"
              >
                <small
                  :contenteditable="editing"
                  spellcheck="false"
                  data-title="Nota"
                  @blur="updateProperty('heroSubparagraph', $event)"
                  v-html="campaignLandingPageConfig.heroSubparagraph"
                ></small>
              </p>
              <div
                class="my-3 subs-box"
                v-if="checkProjectType === ProjectTypes.Voluntariado"
              >
                <v-layout subs-container align-center flex-wrap>
                  <div class="flex-grow-1">
                    Há
                    <b
                      ><strong>{{ totalSubscribers }}</strong></b
                    >
                    inscritos
                  </div>
                  <span v-if="totalFreeOffers > 0">
                    Faltam
                    <strong
                      ><b>{{ totalFreeOffers }}</b></strong
                    >
                    voluntários
                  </span>
                  <span v-else>
                    <strong><b>Todas as vagas estão preenchidas!</b></strong>
                  </span>
                </v-layout>
                <div class="progress">
                  <span :style="`width: ${offersPercentage}%`"></span>
                </div>
              </div>
            </div>

            <!-- dropzone -->
            <div
              class="dropzone font-principal"
              :class="{ active: heroUploadImage }"
              :v-if="editing"
            >
              <button
                class="close-dropzone"
                @click="heroUploadImage = !heroUploadImage"
              >
                <i class="fal fa-times"></i>
              </button>
              <!--active-->
              <input type="file" accept="image/*" @change="heroImageChanged" />
              <i class="fas fa-cloud-upload"></i>
              <h4>ARRASTE E SOLTE</h4>
              <p>
                uma imagem aqui, ou
                <span>procure</span>
              </p>
            </div>

            <div
              class="overlay"
              :style="{
                opacity: 0.8 - campaignLandingPageConfig.heroOverlayOpacity,
              }"
            ></div>

            <!-- <div class="vejamais">
              veja mais
              <i class="fal fa-chevron-down d-block mt-1"></i>
            </div>-->
          </v-flex>
        </v-layout>
      </div>
    </div>

    <!-- BLOCOS ######################### -->
    <template v-for="current in campaignLandingPageConfig.blockOrderArray">
      <!-- BLOCO - NUMEROS ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.NumberBlock"
      >
        <section
          :key="current"
          class="bloco numeros bg-theme-white"
          :class="{ hidden: !campaignLandingPageConfig.showNumberBlock }"
          data-title="Números"
          v-if="editing || campaignLandingPageConfig.showNumberBlock"
          ref="numbersBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.NumberBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.NumberBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showNumberBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.numberSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('numberSectionName', $event)"
            />
          </div>
          <div class="container">
            <v-layout wrap align-center bloco-content>
              <v-flex md6 sm12 xs12>
                <p
                  :contenteditable="editing"
                  spellcheck="false"
                  data-title="Parágrafo"
                  @blur="updateProperty('numberParagraph', $event)"
                  v-html="campaignLandingPageConfig.numberParagraph"
                ></p>
              </v-flex>
              <v-flex md6 sm12 xs12 text-xs-center py-3>
                <h4
                  :contenteditable="editing"
                  spellcheck="false"
                  data-title="Sub-título"
                  class="d-inline-block"
                  @blur="updateProperty('numberSubtitle', $event)"
                  v-html="campaignLandingPageConfig.numberSubtitle"
                ></h4>
                <v-layout mt-3j justify-center mt-3>
                  <v-flex
                    shrink
                    mx-2
                    eye-hidden-block
                    v-if="
                      editing ||
                        campaignLandingPageConfig.showDirectBeneficiariesNumber
                    "
                  >
                    <a
                      href="javascript:;"
                      v-if="editing"
                      @click="
                        toggleHiddenClass('showDirectBeneficiariesNumber')
                      "
                    >
                      <Icon name="fal fa-eye-slash" colorFont="#65145E"></Icon>
                    </a>
                    <h6>Beneficiários Diretos</h6>
                    <h3
                      :contenteditable="editing"
                      @input="allowOnlyNumber"
                      @blur="
                        updateBeneficiariesProperty(
                          'directBeneficiariesValue',
                          $event
                        )
                      "
                      v-html="
                        formatterHelper.formatMoney(directBeneficiaries, 0)
                      "
                    ></h3>
                  </v-flex>
                  <v-flex
                    ml-5
                    shrink
                    mx-2
                    eye-hidden-block
                    v-if="
                      editing ||
                        campaignLandingPageConfig.showIndirectBeneficiariesNumber
                    "
                  >
                    <a
                      href="javascript:;"
                      v-if="editing"
                      @click="
                        toggleHiddenClass('showIndirectBeneficiariesNumber')
                      "
                    >
                      <Icon name="fal fa-eye-slash" colorFont="#65145E"></Icon>
                    </a>
                    <h6>Beneficiários Indiretos</h6>
                    <h3
                      :contenteditable="editing"
                      @input="allowOnlyNumber"
                      @blur="
                        updateBeneficiariesProperty(
                          'indirectBeneficiariesValue',
                          $event
                        )
                      "
                      v-html="
                        formatterHelper.formatMoney(indirectBeneficiaries, 0)
                      "
                    ></h3>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
          <v-layout v-if="editing" alert-number-section-lp>
            <v-flex>
              <p>
                Você poderá exibir um número personalizado de beneficiários
                diretos e indiretos. Digite aqui o número desejado ou deixe o
                campo em branco para exibir a soma de beneficiários dos projetos
                de sua campanha
              </p>
            </v-flex>
          </v-layout>
        </section>
      </template>

      <!-- BLOCO - VAZIO ######################### -->
      <template v-if="current == campaignLandingPageBlockIdentifier.EmptyBlock">
        <section
          :key="current"
          class="bloco numeros bg-theme-white"
          :class="{ hidden: !campaignLandingPageConfig.showEmptyBlock }"
          data-title="Bloco Vazio"
          v-if="editing || campaignLandingPageConfig.showEmptyBlock"
          ref="emptyBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.EmptyBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.EmptyBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showEmptyBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.emptyBlockSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('emptyBlockSectionName', $event)"
            />
          </div>
          <!-- <div id="empty-block" class="container notranslate"> -->
          <div
            id="empty-block"
            class="container notranslate"
            :contenteditable="editing"
            spellcheck="false"
            data-title="HTML"
            @blur="updateProperty('emptyBlockHtml', $event)"
            v-html="campaignLandingPageConfig.emptyBlockHtml"
          ></div>
          <!-- </div> -->
          <v-layout v-if="editing" alert-number-section-lp>
            <v-flex>
              <p>
                Você poderá exibir um número personalizado de beneficiários
                diretos e indiretos. Digite aqui o número desejado ou deixe o
                campo em branco para exibir a soma de beneficiários dos projetos
                de sua campanha
              </p>
            </v-flex>
          </v-layout>
        </section>
      </template>

      <!-- PROJETOS ######################### -->

      <template
        v-if="current == campaignLandingPageBlockIdentifier.ProjectBlock"
      >
        <section
          :key="current"
          class="bloco projetos bg-theme-white"
          data-title="Projetos"
          id="projectsBlock"
          ref="projectsBlock"
          :class="{
            hidden: !campaignLandingPageConfig.showProjectsBlock,
          }"
          v-if="
            campaignLandingPageConfig.projectsCount > 1 &&
              (editing || campaignLandingPageConfig.showProjectsBlock)
          "
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.ProjectBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.ProjectBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showProjectsBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.projectsSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('projectsSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                class="d-inline-block"
                data-title="Título"
                @blur="updateProperty('projectListTitle', $event)"
                v-html="campaignLandingPageConfig.projectListTitle"
              ></h2>
            </div>
            <div class="wrapper mt-0 bg-projeto-white">
              <!-- v-show="causeList && causeList.length > 1" -->
              <!-- v-show="coverageList && coverageList.length > 1" -->
              <v-layout
                row
                wrap
                w-100
                :class="{
                  'hidden-desktop':
                    campaignLandingPageConfig.projectsCount <= 4,
                }"
              >
                <div class="filter-modify">
                  <div class="display-flex">
                    <InputSelect
                      :items="causeList"
                      valueAttribute="id"
                      textAttribute="name"
                      v-model="selectedCause"
                      @input="searchProjects()"
                      unselectedText="Todos"
                      placeholderText="Causa"
                      :useAnotherDefaultValue="true"
                      class="left-select"
                    ></InputSelect>
                    <InputSelect
                      :items="coverageList"
                      valueAttribute="name"
                      textAttribute="name"
                      v-model="selectedCoverage"
                      @input="searchProjects()"
                      unselectedText="Todos"
                      placeholderText="Abrangência"
                      :useAnotherDefaultValue="true"
                      class="right-select"
                    ></InputSelect>
                  </div>
                  <div class="display-flex justify-center">
                    <div class="count-projects">
                      {{ filteredProjects.length }} /
                      {{ campaignLandingPageConfig.projectsCount }}
                    </div>
                  </div>
                </div>
              </v-layout>
              <a
                href="javascript:document.getElementById('projetosWrapper').scrollBy(454, 0);"
                class="scroll-arrow next proj-next"
              >
                <Icon name="far fa-chevron-right" size="16" />
              </a>
              <a
                href="javascript:document.getElementById('projetosWrapper').scrollBy(-454, 0);"
                class="scroll-arrow prev proj-prev"
                style="display: none"
              >
                <Icon name="far fa-chevron-left" size="16" />
              </a>
              <div
                class="project-landing slider-h wrapper"
                :class="verifyWindows() ? 'no-scroll' : ''"
                ref="projectsSlick"
                id="projetosWrapper"
                @scroll="slickOnScroll"
              >
                <template
                  v-if="
                    !campaignLandingPageConfig.projectList ||
                      campaignLandingPageConfig.projectList.length == 0
                  "
                >
                  <div
                    v-for="i in Math.min(
                      6,
                      campaignLandingPageConfig.projectsCount
                    )"
                    :key="i"
                    class="item"
                  >
                    <div class="projeto ph-load ph-project loading">
                      <div class="main-img">
                        <!-- <img src="https://images.unsplash.com/photo-1490129811213-02a6f2df1c35" alt /> -->
                      </div>
                      <div class="logo-inst">
                        <!-- <img :src="getInstitutionImage(project)" /> -->
                      </div>
                      <div class="project-type color-theme-texto">
                        <Icon
                          name="fal fa-info-circle"
                          class="ml-2"
                          size="18"
                        />Lorem Ipsum
                      </div>

                      <div class="content">
                        <h5>Instituição</h5>
                        <h3>Título do projeto</h3>
                        <h6>causa | público</h6>
                        <p>
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts. Separated they live in Bookmarksgrove
                          right at the coast of the Semantics, a large language
                          ocean.
                        </p>
                        <v-layout justify-space-between>
                          <v-flex shrink>
                            <small>
                              Captado no projeto:
                              <b>R$ 12.435</b>
                            </small>
                          </v-flex>
                          <v-flex shrink>
                            <small>
                              Objetivo:
                              <b>R$ 120.435</b>
                            </small>
                          </v-flex>
                        </v-layout>
                        <div class="progress-bar">
                          <span
                            :style="{ width: calculatePercentual(50, 100) }"
                          ></span>
                        </div>
                        <v-layout justify-space-between mt-4>
                          <v-flex shrink>
                            <small>
                              Captado na Campanha:
                              <b>R$ 120.435</b>
                            </small>
                          </v-flex>
                        </v-layout>
                        <div class="progress-bar">
                          <span
                            :style="{ width: calculatePercentual(50, 100) }"
                          ></span>
                        </div>
                        <v-layout mt-4>
                          <v-flex shrink>
                            <v-btn
                              flat
                              round
                              color="white"
                              class="btn-primary ml-0 mr-3 px-4"
                              large
                              >Quero doar</v-btn
                            >
                            <v-btn
                              flat
                              round
                              color="white"
                              class="btn-white ml-0 mr-3 px-4"
                              large
                              >Saiba mais</v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </div>
                      <div class="footer">
                        <v-layout justify-end align-right>
                          <v-flex shrink>
                            Beneficiários diretos
                            <span class="badge">654</span>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    v-for="project in filteredProjects"
                    :key="project.id"
                    class="item"
                  >
                    <div class="projeto">
                      <div class="main-img">
                        <a
                          href="javascript:;"
                          class="map-marker-project"
                          @click="showLocationBox(project.id)"
                          title="Abrangência do Projeto"
                          v-if="project.coverageList.length > 0"
                        >
                          <Icon name="fas fa-map-marker-alt" size="16" />
                        </a>
                        <template
                          v-if="
                            project.projectTypeId ==
                              ProjectTypes.Voluntariado &&
                              project.volunteeringHeroFile
                          "
                        >
                          <FileImage :file="project.volunteeringHeroFile" />
                        </template>
                        <template
                          v-else-if="project.projectLandingPageConfig.heroFile"
                        >
                          <FileImage
                            :file="project.projectLandingPageConfig.heroFile"
                          />
                        </template>
                        <!-- <FileImage v-if="project.projectTypeId != ProjectTypes.Voluntariado" :file="project.projectLandingPageConfig.heroFile" /> -->
                      </div>
                      <div
                        class="logo-inst"
                        v-show="project.institution.file != null"
                      >
                        <!-- <img :src="getInstitutionImage(project)" />
                        -->
                        <FileImage :file="project.institution.file" />
                      </div>
                      <div class="project-type">
                        <button
                          @click="openInfoDialog(project.projectTypeId)"
                          class="color-theme-texto"
                        >
                          {{ getProjectTypeName(project.projectTypeId) }}
                          <Icon
                            name="fal fa-info-circle f-size-16"
                            class="ml-2"
                            size="18"
                          />
                        </button>
                      </div>
                      <div class="content">
                        <h5>{{ project.institution.entity.name }}</h5>
                        <h3 :title="project.shortName">
                          {{ project.shortName }}
                        </h3>
                        <h6 class="mb-0">
                          <span title="Causa">{{ project.cause.name }}</span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span title="Público">
                            {{
                              project.targetAudience
                                ? project.targetAudience.name
                                : ""
                            }}
                          </span>
                        </h6>
                        <!-- <span class="f-size-12">{{getCoverageName(project.coverageList)}}</span> Abrangência ão é aqui-->
                        <template
                          v-if="
                            project.projectTypeId != ProjectTypes.Voluntariado
                          "
                        >
                          <p :title="project.objective">
                            {{
                              commonHelper.getSubstring(project.objective, 170)
                            }}
                          </p>
                          <v-layout justify-space-between>
                            <v-flex shrink>
                              <small>
                                Captação total:
                                <b
                                  :title="
                                    'R$ ' +
                                      formatterHelper.formatMoney(
                                        project.totalDonatedValueWithCapturedValue,
                                        0
                                      )
                                  "
                                >
                                  R$
                                  {{
                                    formatterHelper.formatShortNumber(
                                      project.totalDonatedValueWithCapturedValue,
                                      1
                                    )
                                  }}
                                </b>
                              </small>
                            </v-flex>
                            <v-flex shrink>
                              <small>
                                Objetivo:
                                <b
                                  :title="
                                    'R$ ' +
                                      formatterHelper.formatMoney(
                                        project.totalValue,
                                        0
                                      )
                                  "
                                >
                                  R${{
                                    formatterHelper.formatShortNumber(
                                      project.totalValue,
                                      1
                                    )
                                  }}
                                </b>
                              </small>
                            </v-flex>
                          </v-layout>
                          <div class="progress-bar">
                            <span
                              :style="{
                                width: calculatePercentual(
                                  project.totalDonatedValueWithCapturedValue,
                                  project.totalValue
                                ),
                              }"
                            ></span>
                          </div>
                        </template>
                        <!-- IF -- se R$0 não mostra -->
                        <div style="height: 35px" class="mt-3">
                          <div
                            v-if="project.totalDonatedValueOnlyForCampaign != 0"
                          >
                            <v-layout justify-space-between>
                              <v-flex shrink color-theme-destaque>
                                <small>
                                  <!-- {{campaignLandingPageConfig.campaign.name}}: -->
                                  Captado nesta campanha:
                                  <b
                                    :title="
                                      'R$ ' +
                                        formatterHelper.formatMoney(
                                          project.totalDonatedValueOnlyForCampaign,
                                          0
                                        )
                                    "
                                  >
                                    R$
                                    {{
                                      formatterHelper.formatShortNumber(
                                        project.totalDonatedValueOnlyForCampaign,
                                        1
                                      )
                                    }}
                                  </b>
                                </small>
                              </v-flex>
                            </v-layout>
                            <div class="progress-bar">
                              <span
                                :style="{
                                  width: calculatePercentual(
                                    project.totalDonatedValueOnlyForCampaign,
                                    project.totalValue
                                  ),
                                }"
                                class="bg-theme-destaque"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <!-- end if R$0 -->
                        <v-layout mt-4>
                          <v-flex display-flex shrink>
                            <div @click="gaProject(project)">
                              <v-btn
                                flat
                                round
                                color="white"
                                class="btn-primary ml-0 mr-3 px-4"
                                v-if="project.captureStatus && !campaignPaused"
                                :disabled="
                                  project.projectTypeId ==
                                    ProjectTypes.Ticket &&
                                    project.donationProductList &&
                                    project.donationProductList.length == 0
                                "
                                large
                                :class="
                                  checkIfProjectAlreadyExistInTheCart(project)
                                    ? 'with-check'
                                    : ''
                                "
                                @click="
                                  openConfirmDonationToProjectModal(project)
                                "
                              >
                                <Icon
                                  name="fas fa-check"
                                  class="check-circle"
                                  size="18"
                                  colorFont="#02ACB4"
                                ></Icon>
                                {{
                                  project.projectTypeId ==
                                    ProjectTypes.Ticket &&
                                  project.donationProductList &&
                                  project.donationProductList.length == 0
                                    ? "Esgotado"
                                    : campaignLandingPageConfig.donationButtonText
                                }}
                              </v-btn>
                            </div>
                            <v-btn
                              flat
                              round
                              color="white"
                              class="btn-white ml-0 mr-3 px-4"
                              large
                              @click="
                                gotoProjectLP(project.id);
                                $gtag(
                                  'send',
                                  'event',
                                  'Projeto',
                                  'clicou',
                                  'Saiba Mais'
                                );
                              "
                              >Saiba mais</v-btn
                            >
                          </v-flex>
                          <!--<v-flex shrink>
                  <v-btn flat round class="btn-white ml-0 ma-0 px-4" large>Saiba mais</v-btn>
                          </v-flex>-->
                        </v-layout>
                      </div>
                      <div class="footer">
                        <v-layout justify-end align-right>
                          <!--<v-flex shrink>
                  Doaram:
                  <b>2.543</b>
                </v-flex>
                <v-flex shrink>
                  Visto por:
                  <b>23.509</b>
                          </v-flex>-->
                          <v-flex shrink>
                            Beneficiários diretos
                            <span
                              class="badge"
                              :title="project.directBeneficiaries"
                            >
                              {{
                                formatterHelper.formatShortNumber(
                                  project.directBeneficiaries,
                                  1
                                )
                              }}
                            </span>
                          </v-flex>
                          <!-- <v-flex shrink>
                  Beneficiários indiretos
                  <span class="badge">{{project.indirectBeneficiaries}}</span>
                          </v-flex>-->
                        </v-layout>
                      </div>
                      <div class="apoiadores">
                        <v-layout justify-end align-right>
                          <v-flex shrink>
                            <span
                              v-if="
                                !getSelectedCompaines(
                                  project.projectLandingPageConfig,
                                  true
                                )
                              "
                            >
                              Apoio:
                              {{
                                campaignLandingPageConfig.campaign.company
                                  .entity.name
                              }}
                            </span>
                            <span
                              v-else
                              :title="
                                getSelectedCompaines(
                                  project.projectLandingPageConfig,
                                  false
                                )
                              "
                            >
                              {{
                                getSelectedCompaines(
                                  project.projectLandingPageConfig,
                                  true
                                )
                              }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </div>
                      <template>
                        <!-- :class="active" -->
                        <div
                          :ref="'locationBox' + project.id"
                          class="overlay-location-project"
                          @click="hideLocationBox(project.id)"
                        >
                          <div class="info-location-project">
                            <a
                              href="javascript:;"
                              class="close-location-project"
                              @click="
                                hideLocationBox(project.id);
                                $gtag(
                                  'send',
                                  'event',
                                  'Projeto',
                                  'clicou',
                                  'Abrangência'
                                );
                              "
                            >
                              <Icon
                                name="fal fa-times"
                                size="18"
                                colorFont="#80849A"
                              />
                            </a>
                            <h2>Abrangência</h2>
                            <ul>
                              <li
                                v-for="coverage in project.coverageList"
                                :key="coverage.id"
                              >
                                <Icon
                                  name="fal fa-map-marker-alt"
                                  size="20"
                                  class="mr-3"
                                />
                                {{ coverage.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div
              align="center"
              :class="{
                'hidden-desktop': campaignLandingPageConfig.projectsCount <= 12,
                show: selectedCause != null || selectedCoverage != null,
              }"
            >
              <v-btn
                flat
                round
                color="white"
                class="btn-ghost-primary theme--light ma-0 mt-5 px-4"
                large
                @click="
                  gotoProjectList(false);
                  $gtag('send', 'event', 'Projeto', 'clicou', 'Explorar todos');
                "
              >
                Explorar todos
                <span class="badge">
                  {{ campaignLandingPageConfig.projectsCount }}
                </span>
              </v-btn>
            </div>
          </div>
        </section>
        <!-- MODAL DE PRODUTOS DE DOAÇÃO ######################### -->
        <DonationProductsList
          :key="'donations' + current"
          ref="listProductsDialog"
          :campaignLandingPage="campaignLandingPageConfig"
        ></DonationProductsList>
      </template>

      <!-- PRODUTOS DE DOAÇÃO ######################### -->
      <template
        v-else-if="
          current == campaignLandingPageBlockIdentifier.DonationProductsBlock
        "
      >
        <section
          :key="current"
          class="bloco projetos bg-theme-white"
          data-title="Produtos de Doação"
          ref="donationProductsBlock"
          :class="{
            hidden:
              !campaignLandingPageConfig.showDonationProductsBlock && editing,
          }"
          v-if="
            editing ||
              (campaignLandingPageConfig.projectList &&
                campaignLandingPageConfig.projectList.length == 1 &&
                campaignLandingPageConfig.projectList[0].donationProductList
                  .length > 0 &&
                !campaignLandingPageConfig.showSimplifiedDonationBlock)
          "
        >
          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="
                goUp(campaignLandingPageBlockIdentifier.DonationProductsBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.DonationProductsBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showDonationProductsBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.donationProductsSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('donationProductsSectionName', $event)"
            />
          </div>
          <div align="center" class="">
            <h2
              :contenteditable="editing"
              spellcheck="true"
              data-title="Título"
              @blur="updateProperty('donationProductsTitle', $event)"
              class="d-inline-block mb-0"
              v-html="campaignLandingPageConfig.donationProductsTitle"
            ></h2>
          </div>
          <div class="bloco-content">
            <DonationProducts
              :showDonationButton="!campaignPaused"
              :isModal="false"
              :project="campaignLandingPageConfig.projectList[0]"
              :donationProductList="
                campaignLandingPageConfig.projectList[0].donationProductList
              "
              :campaignLandingPage="campaignLandingPageConfig"
            />
          </div>
        </section>
      </template>

      <!-- CASOS ######################### -->
      <template v-if="current == campaignLandingPageBlockIdentifier.CaseBlock">
        <section
          :key="current"
          class="bloco casos bg-theme-white"
          data-title="casos"
          :class="{ hidden: !campaignLandingPageConfig.showCasesBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showCasesBlock &&
                campaignLandingPageConfig.campaignCaseList &&
                campaignLandingPageConfig.campaignCaseList.length > 0)
          "
          ref="casesBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <CasesEdit
              v-model="campaignLandingPageConfig.campaignCaseList"
              :campaignId="campaignLandingPageConfig.campaignId"
            ></CasesEdit>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.CaseBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.CaseBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showCasesBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.casesSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('casesSectionName', $event)"
            />
          </div>
          <div align="center" class="spacing-title-landing">
            <h2
              :contenteditable="editing"
              spellcheck="true"
              data-title="Título"
              @blur="updateProperty('casesTitle', $event)"
              class="d-inline-block mb-0"
              v-html="campaignLandingPageConfig.casesTitle"
            ></h2>
          </div>
          <!--  -->
          <div class="bloco-content">
            <slick
              :options="slickOptionsCases"
              ref="casesSlick"
              class="bg-white"
            >
              <i
                v-if="
                  !campaignLandingPageConfig.campaignCaseList ||
                    campaignLandingPageConfig.campaignCaseList.length == 0
                "
              >
                <v-layout wrap align-space-between v-for="i in 1" :key="i">
                  <v-flex sm4 md6 xs12 bg-theme-fundo>
                    <div class="img-container icon-white"></div>
                  </v-flex>
                  <v-flex sm8 md6 xs12 pa-5 display-flex align-center>
                    <div class="text-block">
                      <!-- <h3 class="color-theme-destaque mb-2 font-destaque fw-400">Projeto</h3> -->
                      <h2>
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia
                      </h2>
                      <p>
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        of shining texts
                      </p>
                    </div>
                  </v-flex>
                </v-layout>
              </i>
              <template v-else>
                <v-layout
                  wrap
                  fill-height
                  align-space-between
                  d-flex
                  casos-bloco
                  v-for="(campaignCase,
                  i) in campaignLandingPageConfig.campaignCaseList"
                  :key="i"
                >
                  <v-flex
                    sm4
                    md6
                    xs12
                    casos-imagem
                    v-if="campaignCase.file && campaignCase.file.path"
                  >
                    <FileImage
                      :file="campaignCase.file"
                      :alt="''"
                      class="img-container icon-white"
                      :useBg="true"
                      effects="h_550,w_550,dpr_2.0,c_crop"
                    />
                  </v-flex>
                  <v-flex
                    sm8
                    md6
                    xs12
                    d-flex
                    align-center
                    casos-texto
                    v-if="campaignCase.file && campaignCase.file.path"
                  >
                    <div class="text-block">
                      <v-row>
                        <!-- <h3 class="color-theme-destaque mb-2 font-destaque fw-400">Projeto</h3> -->
                        <h2>{{ campaignCase.title }}</h2>
                        <p v-html="campaignCase.body"></p>
                        <v-layout mt-3 justify-space-between>
                          <div id="div-btn-case">
                            <v-btn
                              flat
                              round
                              color="white"
                              class="btn-primary mx-0 px-4"
                              large
                              @click="goesTo()"
                              v-if="!campaignPaused"
                            >
                              {{ campaignLandingPageConfig.donationButtonText }}
                            </v-btn>
                          </div>
                          <!-- <v-btn flat round color="white" class="btn-default mx-0 px-4" large>Leia mais</v-btn> -->
                        </v-layout>
                      </v-row>
                    </div>
                  </v-flex>
                  <v-flex
                    v-if="!campaignCase.file || !campaignCase.file.path"
                    xs12
                    d-flex
                    flex-column
                    align-center
                    casos-texto
                  >
                    <v-row>
                      <h2>{{ campaignCase.title }}</h2>
                    </v-row>
                    <v-row>
                      <div class="display-flex px-4 flex-gap-30">
                        <p
                          class="notranslate"
                          align="justify"
                          v-html="campaignCase.body"
                        ></p>
                        <p
                          class="notranslate"
                          align="justify"
                          v-html="campaignCase.body2"
                        ></p>
                      </div>
                    </v-row>
                  </v-flex>
                </v-layout>
              </template>
            </slick>
          </div>
        </section>
      </template>
      <!-- PASSOS ######################### -->
      <template v-if="current == campaignLandingPageBlockIdentifier.StepBlock">
        <section
          :key="current"
          class="bloco passos bg-theme-fundo"
          data-title="Passos"
          :class="{ hidden: !campaignLandingPageConfig.showDonationStepsBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showDonationStepsBlock &&
                campaignLandingPageConfig.campaignDonationStepList &&
                campaignLandingPageConfig.campaignDonationStepList.length > 0)
          "
          ref="donationStepsBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <DonationStepEdit
              v-model="campaignLandingPageConfig.campaignDonationStepList"
              :campaignId="campaignLandingPageConfig.campaignId"
            ></DonationStepEdit>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.StepBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.StepBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showDonationStepsBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.donationStepsSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('donationStepsSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block"
                @blur="updateProperty('donationStepsTitle', $event)"
                v-html="campaignLandingPageConfig.donationStepsTitle"
              ></h2>
            </div>
            <a
              href="javascript:document.getElementById('passosWrapper').scrollBy(334, 0);"
              class="scroll-arrow next"
            >
              <Icon name="far fa-chevron-right" size="16" />
            </a>
            <a
              href="javascript:document.getElementById('passosWrapper').scrollBy(-334, 0);"
              class="scroll-arrow prev"
              style="display: none;"
            >
              <Icon name="far fa-chevron-left" size="16" />
            </a>
            <template
              v-if="
                !campaignLandingPageConfig.campaignDonationStepList ||
                  campaignLandingPageConfig.campaignDonationStepList.length == 0
              "
            >
              <div
                class="slider-h wrapper"
                id="passosWrapper"
                @scroll="slickOnScroll"
              >
                <div class="passo item" v-for="i in 4" :key="i">
                  <h3>#{{ i }}</h3>
                  <i class="fas fa-file-image"></i>
                  <h4>Passo {{ i }}</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="slider-h wrapper"
                id="passosWrapper"
                @scroll="slickOnScroll"
              >
                <div
                  class="passo item"
                  v-for="(donationStep,
                  index) in campaignLandingPageConfig.campaignDonationStepList"
                  :key="donationStep.id"
                >
                  <h3 v-if="donationStep.showNumber">#{{ index + 1 }}</h3>
                  <i :class="donationStep.icon"></i>
                  <h4>{{ donationStep.title }}</h4>
                  <p v-html="donationStep.body"></p>
                </div>
              </div>
            </template>
          </div>
        </section>
      </template>

      <!-- PHOTO CARD ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.PhotoCardsBlock"
      >
        <section
          :key="current"
          class="bloco photo-cards bg-theme-fundo"
          data-title="Cards de fotos"
          :class="{ hidden: !campaignLandingPageConfig.showPhotoCardsBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showPhotoCardsBlock &&
                campaignLandingPageConfig.campaignPhotoCardsList &&
                campaignLandingPageConfig.campaignPhotoCardsList.length > 0)
          "
          ref="photoCardsBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <PhotoCardsEdit
              v-model="campaignLandingPageConfig.campaignPhotoCardsList"
              :campaignId="campaignLandingPageConfig.campaignId"
            ></PhotoCardsEdit>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.PhotoCardsBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.PhotoCardsBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showPhotoCardsBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.photoCardsSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('photoCardsSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block"
                @blur="updateProperty('photoCardsTitle', $event)"
                v-html="campaignLandingPageConfig.photoCardsTitle"
              ></h2>
            </div>
            <a
              href="javascript:document.getElementById('photoCardWrapper').scrollBy(334, 0);"
              class="scroll-arrow next"
            >
              <Icon name="far fa-chevron-right" size="16" />
            </a>
            <a
              href="javascript:document.getElementById('photoCardWrapper').scrollBy(-334, 0);"
              class="scroll-arrow prev"
              style="display: none;"
            >
              <Icon name="far fa-chevron-left" size="16" />
            </a>
            <template
              v-if="
                !campaignLandingPageConfig.campaignPhotoCardsList ||
                  campaignLandingPageConfig.campaignPhotoCardsList.length == 0
              "
            >
              <div
                class="slider-h wrapper"
                id="photoCardWrapper"
                @scroll="slickOnScroll"
              >
                <div class="photo-card item" v-for="i in 4" :key="i">
                  <div class="main-img"></div>
                  <h3>Título</h3>
                  <h4>Subtítulo</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="slider-h wrapper"
                id="photoCardWrapper"
                @scroll="slickOnScroll"
              >
                <div
                  class="photo-card item"
                  v-for="photoCard in campaignLandingPageConfig.campaignPhotoCardsList"
                  :key="photoCard.id"
                >
                  <div v-if="photoCard.file">
                    <FileImage
                      :file="photoCard.file"
                      :alt="''"
                      effects="w_400,q_50"
                    />
                  </div>
                  <h3 v-if="photoCard.title">{{ photoCard.title }}</h3>
                  <h4 v-if="photoCard.subtitle">{{ photoCard.subtitle }}</h4>
                  <p
                    class="px-4"
                    v-if="photoCard.text"
                    v-html="photoCard.text"
                  ></p>
                </div>
              </div>
            </template>
          </div>
        </section>
      </template>

      <!-- SIMULACAO ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.SimulationBlock"
      >
        <section
          :key="current"
          class="bloco simulacao bg-theme-secundaria dark"
          data-title="Simulação"
          :class="{ hidden: !campaignLandingPageConfig.showSimulationBlock }"
          v-if="editing || campaignLandingPageConfig.showSimulationBlock"
          ref="simulationBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <!--background-color-->

          <!-- Image background / default opacity .5-->
          <div
            ref="simulationImage"
            class="image-bg"
            :style="{
              backgroundImage: getImage(
                'simulationFile',
                fileService_renderSimulationFileCallback
              ),
              opacity: campaignLandingPageConfig.simulationImageOpacity,
            }"
          ></div>
          <!-- ex: https://images.unsplash.com/photo-1469767902646-29456f6c43f8-->

          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.SimulationBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.SimulationBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showSimulationBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.simulationSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('simulationSectionName', $event)"
            />
          </div>

          <div
            class="dropzone"
            :class="{ active: simulationUploadImage }"
            v-if="editing"
          >
            <button
              class="close-dropzone"
              @click="simulationUploadImage = !simulationUploadImage"
            >
              <i class="fal fa-times"></i>
            </button>
            <!--active-->
            <input
              type="file"
              accept="image/*"
              @change="simulationImageChanged"
            />
            <i class="fas fa-cloud-upload"></i>
            <h4>ARRASTE E SOLTE</h4>
            <p>
              uma imagem aqui, ou
              <span>procure</span>
            </p>
          </div>

          <!-- Overlay / Brightness / default opacity .2-->
          <div
            class="overlay"
            :style="{
              opacity: 0.8 - campaignLandingPageConfig.simulationOverlayOpacity,
            }"
          ></div>

          <!-- tools -->
          <div class="hero-tools right preview-off" v-if="editing">
            <!-- image opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showSimulationImageOpacitySlider }"
            >
              <!--active-->
              <v-slider
                v-model="campaignLandingPageConfig.simulationImageOpacity"
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <!-- overlay opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showSimulationOverlayOpacitySlider }"
            >
              <v-slider
                v-model="campaignLandingPageConfig.simulationOverlayOpacity"
                min="0"
                max=".8"
                step="0.05"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <a
              href
              @click.prevent="toggleSimulationImageUpload()"
              :class="{ active: simulationUploadImage }"
            >
              <i class="fal fa-image"></i>
            </a>
            <!--<a href @click.prevent>
          <i class="fal fa-palette"></i>
            </a>-->
            <a
              href
              @click.prevent="toggleSimulationImageOpacity()"
              :class="{ active: showSimulationImageOpacitySlider }"
            >
              <i class="custom-icon ci-contrast size-20"></i>
            </a>
            <a
              href
              @click.prevent="toggleSimulationOverlayOpacity()"
              :class="{ active: showSimulationOverlayOpacitySlider }"
            >
              <i class="custom-icon ci-brightness size-20"></i>
            </a>
          </div>
          <div class="container bloco-content">
            <v-layout>
              <v-flex text-xs-center>
                <div align="center">
                  <h2
                    :contenteditable="editing"
                    spellcheck="false"
                    data-title="Título"
                    @blur="updateProperty('simulationTitle', $event)"
                    v-html="campaignLandingPageConfig.simulationTitle"
                  ></h2>
                </div>
                <p
                  :contenteditable="editing"
                  spellcheck="false"
                  data-title="Parágrafo"
                  @blur="updateProperty('simulationParagraph', $event)"
                  v-html="campaignLandingPageConfig.simulationParagraph"
                ></p>
                <span data-title="Botão" class="d-inline-block mt-3">
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary mx-0 px-5"
                    @click.stop="
                      landingPageOptions.simulationDrawer = !landingPageOptions.simulationDrawer;
                      $gtag(
                        'send',
                        'event',
                        'Simulador pagina',
                        'Simulacao pagina',
                        'Simulador pagina'
                      );
                    "
                    large
                    >Simular</v-btn
                  >
                </span>
              </v-flex>
            </v-layout>
          </div>
        </section>
      </template>

      <!-- NOTICIAS ######################### -->
      <template v-if="current == campaignLandingPageBlockIdentifier.NewsBlock">
        <section
          :key="current"
          class="bloco noticias bg-theme-fundo"
          data-title="Notícias"
          :class="{ hidden: !campaignLandingPageConfig.showNewsBlock }"
          v-if="editing || campaignLandingPageConfig.showNewsBlock"
          ref="newsBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <NewsEdit
              :showButton="true"
              v-model="campaignLandingPageConfig.campaignNewsList"
              :campaignId="campaignLandingPageConfig.campaignId"
            ></NewsEdit>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.NewsBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.NewsBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showNewsBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.newsSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('newsSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block"
                @blur="updateProperty('newsListTitle', $event)"
                v-html="campaignLandingPageConfig.newsListTitle"
              ></h2>
            </div>

            <div class="container wrapper mt-4">
              <template
                v-if="
                  !campaignLandingPageConfig.campaignNewsList ||
                    campaignLandingPageConfig.campaignNewsList.length == 0
                "
              >
                <!-- noticia-->
                <div class="noticia" v-for="i in 3" :key="i">
                  <v-layout>
                    <v-flex mr-4 shrink img-noticias>
                      <div class="img-container"></div>
                    </v-flex>
                    <v-flex>
                      <h3>
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </h3>
                      <p>
                        A small river named Duden flows by their place and
                        supplies it with the...
                      </p>
                      <!-- <a href>Leia mais</a> -->
                    </v-flex>
                  </v-layout>
                </div>
              </template>
              <!-- noticia-->
              <template v-else>
                <div
                  class="noticia"
                  v-for="(news,
                  index) in campaignLandingPageConfig.campaignNewsList"
                  :key="news.id"
                  v-show="index < 2 || showAllNews"
                >
                  <v-layout>
                    <v-flex mr-4 shrink img-noticias>
                      <div class="img-container">
                        <!-- <img src alt> -->
                        <FileImage :file="news.file" :alt="''" />
                        <!-- <CampaignNewsImage :news="news" :alt="''"/> -->
                      </div>
                    </v-flex>
                    <div class="display-flex flex-column">
                      <h3>{{ news.title }}</h3>
                      <p v-html="commonHelper.getSubstring(news.body, 70)"></p>
                      <a @click="openModalNews(news)" class="mt-auto"
                        >Leia mais</a
                      >
                    </div>
                  </v-layout>
                </div>
              </template>
            </div>
            <div
              align="center"
              v-show="
                !showAllNews &&
                  campaignLandingPageConfig.campaignNewsList.length > 2
              "
            >
              <v-btn
                flat
                round
                class="btn-ghost-secondary mt-4 px-5"
                large
                @click="showAllNews = true"
                >mais notícias</v-btn
              >
            </div>
          </div>
          <ModalNews :dialog="dialogNews" @close="closeModalNews()">
            <template>
              <div v-if="modalNewsObj" class="modal-noticia-block">
                <div class="img-modal-noticia">
                  <FileImage
                    :useBg="true"
                    :file="modalNewsObj.file"
                    :alt="''"
                  />
                </div>
                <div class="pa-5 combo-text">
                  <h2 class="pt-3">{{ modalNewsObj.title }}</h2>
                  <p
                    class="pt-2 f-size-16 body-news color-theme-texto"
                    v-html="modalNewsObj.body"
                  ></p>
                </div>
              </div>
            </template>
          </ModalNews>
        </section>
      </template>

      <!-- AGRADECIMENTOS ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.AppreciationBlock"
      >
        <section
          :key="current"
          class="bloco agradecimentos bg-theme-white"
          iconButton="ml-3 fal fa-pen-square"
          data-title="Agradecimentos"
          :class="{ hidden: !campaignLandingPageConfig.showAppreciationBlock }"
          v-if="editing || campaignLandingPageConfig.showAppreciationBlock"
          ref="appreciationBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <AppreciationEdit
              textContent="Editar agradecimentos"
              size="16"
              iconButton="ml-3 fal fa-pen-square"
              v-model="campaignLandingPageConfig.campaignAppreciationList"
              :campaignId="campaignLandingPageConfig.campaignId"
              :projectList="campaignLandingPageConfig.projectList"
            ></AppreciationEdit>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="
                goUp(campaignLandingPageBlockIdentifier.AppreciationBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.AppreciationBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showAppreciationBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.appreciationSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('appreciationSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block mt-4 mb-5"
                @blur="updateProperty('appreciationTitle', $event)"
                v-html="campaignLandingPageConfig.appreciationTitle"
              ></h2>
            </div>

            <button type="button" class="scroll-arrow prev" id="agradPrevArrow">
              <Icon name="far fa-chevron-left" size="16" />
            </button>
            <button type="button" class="scroll-arrow next" id="agradNextArrow">
              <Icon name="far fa-chevron-right" size="16" />
            </button>

            <div class="slider-h slick-agrad wrapper" id="agradWrapper">
              <slick
                :options="slickOptionsAgrad"
                ref="agradSlick"
                :key="campaignLandingPageConfig.campaignAppreciationList.length"
              >
                <template
                  v-if="
                    !campaignLandingPageConfig.campaignAppreciationList ||
                      campaignLandingPageConfig.campaignAppreciationList
                        .length == 0
                  "
                >
                  <div class="item">
                    <h2>até R$300</h2>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries
                    </p>
                    <!-- <v-btn flat round color="white" class="btn-primary ma-0 mt-4 px-5" large>Quero doar</v-btn> -->
                  </div>

                  <div class="item">
                    <h2>de R$300 até R$600</h2>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries
                    </p>
                    <!-- <v-btn flat round color="white" class="btn-primary ma-0 mt-4 px-5" large>Quero doar</v-btn> -->
                  </div>

                  <div class="item">
                    <h2>mais de R$600</h2>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries
                    </p>
                    <!-- <v-btn flat round color="white" class="btn-primary ma-0 mt-4 px-5" large>Quero doar</v-btn> -->
                  </div>
                </template>
                <template v-else>
                  <div
                    class="item"
                    v-for="(appreciation,
                    i) in campaignLandingPageConfig.campaignAppreciationList"
                    :key="appreciation.id"
                    :class="{ highlight: i === isMiddle }"
                  >
                    <div class="box-img" v-if="appreciation.file != null">
                      <img :src="appreciation.file.path" />
                    </div>
                    <h1 v-if="appreciation.name">{{ appreciation.name }}</h1>
                    <h2
                      v-if="
                        appreciation.firstValue == null ||
                          appreciation.firstValue == ''
                      "
                    >
                      até R${{
                        formatterHelper.formatMoney(appreciation.lastValue)
                      }}
                    </h2>
                    <h2
                      v-else-if="
                        appreciation.lastValue == null ||
                          appreciation.lastValue == ''
                      "
                    >
                      mais de R${{
                        formatterHelper.formatMoney(appreciation.firstValue)
                      }}
                    </h2>
                    <h2 v-else>
                      de R${{
                        formatterHelper.formatMoney(appreciation.firstValue)
                      }}
                      até R${{
                        formatterHelper.formatMoney(appreciation.lastValue)
                      }}
                    </h2>
                    <div v-if="!appreciation.htmlContent">
                      <ul>
                        <li
                          v-for="appreciationItem in appreciation.campaignAppreciationItems"
                          :key="appreciationItem.id"
                          :class="{ highlight: appreciationItem.highlight }"
                        >
                          <i :class="appreciationItem.icon"></i>
                          <div v-html="appreciationItem.description"></div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="item-body"
                      v-html="appreciation.description"
                    ></div>
                    <div v-if="!campaignPaused" class="item-footer">
                      <v-btn
                        flat
                        round
                        color="white"
                        class="btn-primary ma-0"
                        large
                        @click="
                          openModalProjects(appreciation);
                          $gtag(
                            'send',
                            'event',
                            'Agradecimentos',
                            'clicou',
                            'Quero doar'
                          );
                        "
                        v-if="!campaignPaused"
                        >{{
                          campaignLandingPageConfig.donationButtonText
                        }}</v-btn
                      >
                    </div>
                  </div>
                </template>
              </slick>
            </div>
          </div>
        </section>
      </template>

      <!-- DEPOIMENTOS ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.TestimonyBlock"
      >
        <section
          :key="current"
          class="bloco depoimentos bg-theme-secundaria dark"
          data-title="Depoimentos"
          :class="{ hidden: !campaignLandingPageConfig.showTestimonyBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showTestimonyBlock &&
                campaignLandingPageConfig.campaignTestimonyList &&
                campaignLandingPageConfig.campaignTestimonyList.length > 0)
          "
          ref="testimonyBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <Testimony
              v-model="campaignLandingPageConfig.campaignTestimonyList"
              :campaignId="campaignLandingPageConfig.campaignId"
            ></Testimony>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.TestimonyBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.TestimonyBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showTestimonyBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.testimonySectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('testimonySectionName', $event)"
            />
          </div>

          <!-- dropzone -->
          <div
            class="dropzone"
            :class="{ active: testimonyUploadImage }"
            v-if="editing"
          >
            <!--active-->
            <input
              type="file"
              accept="image/*"
              @change="testimonyImageChanged"
            />
            <i class="fas fa-cloud-upload"></i>
            <h4>ARRASTE E SOLTE</h4>
            <p>
              uma imagem aqui, ou
              <span>procure</span>
            </p>
          </div>

          <!-- Overlay / Brightness / default opacity .2-->
          <div
            class="overlay"
            :style="{
              opacity: 1 - campaignLandingPageConfig.testimonyOverlayOpacity,
            }"
          ></div>

          <!-- Image background / default opacity .5-->
          <div
            ref="testimonyImage"
            class="image-bg"
            :style="{
              backgroundImage: getImage(
                'testimonyFile',
                fileService_renderTestimonyFileCallback
              ),
              opacity: campaignLandingPageConfig.testimonyImageOpacity,
            }"
          ></div>
          <!-- ex: https://images.unsplash.com/photo-1469767902646-29456f6c43f8-->

          <!-- tools -->
          <div class="hero-tools right preview-off" v-if="editing">
            <!-- image opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showTestimonyImageOpacitySlider }"
            >
              <!--active-->
              <v-slider
                v-model="campaignLandingPageConfig.testimonyImageOpacity"
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <!-- overlay opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showTestimonyOverlayOpacitySlider }"
            >
              <v-slider
                v-model="campaignLandingPageConfig.testimonyOverlayOpacity"
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <a
              href
              :class="{ active: testimonyUploadImage }"
              @click.prevent="toggleTestimonyImageUpload()"
            >
              <i class="fal fa-image"></i>
            </a>
            <!--<a href>
          <i class="fal fa-palette"></i>
            </a>-->
            <a
              href
              @click.prevent="toggleTestimonyImageOpacity()"
              :class="{ active: showTestimonyImageOpacitySlider }"
            >
              <i class="custom-icon ci-contrast size-20"></i>
            </a>
            <a
              href
              @click.prevent="toggleTestimonyOverlayOpacity()"
              :class="{ active: showTestimonyOverlayOpacitySlider }"
            >
              <i class="custom-icon ci-brightness size-20"></i>
            </a>
          </div>
          <!-- Ana -->
          <div class="container bloco-content text-white">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block"
                @blur="updateProperty('testimonyTitle', $event)"
                v-html="campaignLandingPageConfig.testimonyTitle"
              ></h2>
            </div>
            <div class="slick-depoimentos">
              <slick :options="slickOptionsDepoimento" ref="depoimentosSlick">
                <template
                  v-if="
                    !campaignLandingPageConfig.campaignTestimonyList ||
                      campaignLandingPageConfig.campaignTestimonyList.length == 0
                  "
                >
                  <div class="item">
                    <div class="avatar">
                      <img src alt />
                    </div>
                    <h4>Person of the world</h4>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries
                    </p>
                    <!-- <a href>Leia mais</a> -->
                  </div>

                  <div class="item">
                    <div class="avatar">
                      <img src alt />
                    </div>
                    <h4>Person of the world</h4>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries
                    </p>
                    <!-- <a href>Leia mais</a> -->
                  </div>

                  <div class="item">
                    <div class="avatar">
                      <img src alt />
                    </div>
                    <h4>Person of the world</h4>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries
                    </p>
                    <!-- <a href>Leia mais</a> -->
                  </div>
                </template>
                <template v-else>
                  <div
                    class="item"
                    v-for="testimony in campaignLandingPageConfig.campaignTestimonyList"
                    :key="testimony.id"
                  >
                    <div
                      class="avatar"
                      :class="{ 'avatar-square': testimony.photoSquare }"
                    >
                      <!-- <CampaignTestimonyImage :testimony="testimony" :alt="''"/> -->
                      <FileImage
                        :file="testimony.file"
                        :alt="''"
                        effects="c_crop,q_80,g_face:auto,h_400,w_400"
                      />
                    </div>
                    <h4 class="notranslate">{{ testimony.name }}</h4>
                    <p v-html="testimony.text"></p>
                    <a
                      @click="
                        openReadMoreTestimony(
                          testimony,
                          campaignLandingPageConfig.testimonyTitle
                        )
                      "
                      v-if="testimony.text.length >= 200"
                      >Leia mais</a
                    >
                  </div>
                </template>
              </slick>
            </div>
          </div>
        </section>
      </template>

      <!-- VÍDEOS ######################### -->
      <template v-if="current == campaignLandingPageBlockIdentifier.VideoBlock">
        <section
          :key="current"
          class="bloco video bg-theme-secundaria dark"
          data-title="Vídeo"
          :class="{ hidden: !campaignLandingPageConfig.showVideoBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showVideoBlock &&
                campaignLandingPageConfig.videoLink)
          "
          ref="videoBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div
            ref="videoImage"
            class="image-bg"
            :style="{
              backgroundImage: getImage(
                'videoFile',
                fileService_renderVideoFileCallback
              ),
              opacity: campaignLandingPageConfig.videoImageOpacity,
            }"
          ></div>

          <div class="edit" v-if="editing">
            <LinkVideo
              v-model="campaignLandingPageConfig.videoLink"
              @saveLink="save()"
            ></LinkVideo>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.VideoBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.VideoBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showVideoBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.videoSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('videoSectionName', $event)"
            />
          </div>

          <div class="hero-tools right preview-off" v-if="editing">
            <!-- image opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showVideoImageOpacitySlider }"
            >
              <!--active-->
              <v-slider
                v-model="campaignLandingPageConfig.videoImageOpacity"
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>

            <div
              class="sub-box slider"
              :class="{ active: showVideoOverlayOpacitySlider }"
            >
              <v-slider
                v-model="campaignLandingPageConfig.videoOverlayOpacity"
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <a
              href="#"
              :class="{ active: videoUploadImage }"
              @click.prevent="toggleVideoImageUpload()"
            >
              <i class="fal fa-image"></i>
            </a>
            <!--<a href>
          <i class="fal fa-palette"></i>
            </a>-->
            <a
              href
              @click.prevent="toggleVideoImageOpacity()"
              :class="{ active: showVideoImageOpacitySlider }"
            >
              <i class="custom-icon ci-contrast size-20"></i>
            </a>
            <a
              href
              @click.prevent="toggleVideoOverlayOpacity()"
              :class="{ active: showVideoOverlayOpacitySlider }"
            >
              <i class="custom-icon ci-brightness size-20"></i>
            </a>
          </div>

          <!-- dropzone -->
          <div class="dropzone" :class="{ active: videoUploadImage }">
            <!--active-->
            <input type="file" accept="image/*" @change="videoImageChanged" />
            <i class="fas fa-cloud-upload"></i>
            <h4>ARRASTE E SOLTE</h4>
            <p>
              uma imagem aqui, ou
              <span>procure</span>
            </p>
          </div>

          <!-- Overlay / Brightness / default opacity .2-->
          <div
            class="overlay"
            :style="{
              opacity: 0.8 - campaignLandingPageConfig.videoOverlayOpacity,
            }"
          ></div>
          <div class="container bloco-content">
            <div class="text-block">
              <div align="center">
                <h2
                  :contenteditable="editing"
                  spellcheck="false"
                  class="d-inline-block notranslate"
                  data-title="Título"
                  @blur="updateProperty('videoTitle', $event)"
                  v-html="campaignLandingPageConfig.videoTitle"
                ></h2>
              </div>
              <p
                :contenteditable="editing"
                spellcheck="false"
                data-title="Parágrafo"
                class="mt-4 notranslate"
                @blur="updateProperty('videoParagraph', $event)"
                v-html="campaignLandingPageConfig.videoParagraph"
              ></p>
              <v-btn
                flat
                round
                color="white"
                class="btn-primary mt-4 px-5"
                large
                @click="
                  toggleWatchVideo();
                  $gtag(
                    'send',
                    'event',
                    'Botão video',
                    'Vídeo',
                    'Assistir vídeo'
                  );
                "
                >Assistir</v-btn
              >
            </div>
          </div>
          <div class="video-player" :class="{ active: watchVideo }">
            <iframe
              id="ytplayer"
              type="text/html"
              width="100%"
              height="100%"
              :src="getVideoLink()"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            />
          </div>
        </section>
      </template>

      <!-- GALERIA DE FOTOS ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.PhotoGalleryBlock"
      >
        <section
          :key="current"
          class="bloco image-grid bg-theme-white"
          data-title="Galeria de Fotos"
          :class="{ hidden: !campaignLandingPageConfig.showPhotoGalleryBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showPhotoGalleryBlock &&
                campaignLandingPageConfig.campaignImageList &&
                campaignLandingPageConfig.campaignImageList.length > 0)
          "
          ref="photoGalleryBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <GalleryDonation
              :showButton="true"
              :campaignId="campaignLandingPageConfig.campaignId"
              v-model="campaignLandingPageConfig.campaignImageList"
            ></GalleryDonation>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="
                goUp(campaignLandingPageBlockIdentifier.PhotoGalleryBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.PhotoGalleryBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showPhotoGalleryBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.photoGallerySectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('photoGallerySectionName', $event)"
            />
          </div>
          <div class="bloco-content" style="position: unset">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block"
                @blur="updateProperty('photoGalleryTitle', $event)"
                v-html="campaignLandingPageConfig.photoGalleryTitle"
              ></h2>
            </div>
            <vue-easy-lightbox
              :visible="galleryLightboxVisible"
              :imgs="getGalleryLightboxImgs()"
              :index="galleryLightboxIndex"
              @hide="galleryLightboxVisible = false"
            ></vue-easy-lightbox>

            <!-- guardar para caso queiram vazio
            <div class="empty-state">
                <div><img src="../../assets/images/cam@2x.png" width="90" alt=""></div>
                <p class="mt-2">É hora de sair e tirar fotos incríveis.</p>
                <v-btn flat round color="white" class="btn-red mt-4 px-5" large>Enviar fotos</v-btn>
            </div>-->

            <div class="wrapper">
              <!-- quando houver fotos -->
              <template
                v-if="
                  !campaignLandingPageConfig.campaignImageList ||
                    campaignLandingPageConfig.campaignImageList.length == 0
                "
              >
                <div class="item" v-for="i in 9" :key="i">
                  <img src alt />
                </div>
              </template>
              <template v-else>
                <div
                  class="item"
                  v-for="(gallery,
                  index) in campaignLandingPageConfig.campaignImageList"
                  :key="gallery.id"
                >
                  <div class="tools">
                    <a
                      href="javascript:;"
                      class="fal fa-search-plus"
                      @click="openGalleryLightbox(index)"
                    ></a>
                    <!-- <a href="javascript:;" class="fal fa-trash"></a> -->
                  </div>
                  <FileImage :file="gallery.file" effects="w_700,q_80" />
                  <!-- <img src="https://source.unsplash.com/featured/?water" alt> -->
                </div>
              </template>
            </div>
          </div>
        </section>
      </template>

      <!-- PARCEIROS  ######################### -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.PartnerBlock"
      >
        <section
          :key="current"
          class="bloco parceiros bg-theme-white"
          data-title="Parceiros"
          :class="{ hidden: !campaignLandingPageConfig.showPartnerBlock }"
          v-if="
            editing ||
              (campaignLandingPageConfig.showPartnerBlock &&
                campaignLandingPageConfig.campaignPartnerList &&
                campaignLandingPageConfig.campaignPartnerList.length > 0)
          "
          ref="partnerBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>
          <div class="edit" v-if="editing">
            <Partners
              :campaignId="campaignLandingPageConfig.campaignId"
              v-model="campaignLandingPageConfig.campaignPartnerList"
            ></Partners>
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="goUp(campaignLandingPageBlockIdentifier.PartnerBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="goDown(campaignLandingPageBlockIdentifier.PartnerBlock)"
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showPartnerBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.partnerSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('partnerSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <div align="center">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block"
                @blur="updateProperty('partnerTitle', $event)"
                v-html="campaignLandingPageConfig.partnerTitle"
              ></h2>
            </div>
            <div class="wrapper mt-5 bloco-parceiros">
              <slick :options="slickOptionsPartner" ref="partnersSlick">
                <template
                  v-if="
                    campaignLandingPageConfig.campaignPartnerList.length <= 0
                  "
                >
                  <div class="parceiro-imagem" v-for="i in 7" :key="i">
                    <div class="sem-imagem"></div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="parceiro-imagem"
                    v-for="campaignPartner in campaignLandingPageConfig.campaignPartnerList"
                    :key="campaignPartner.id"
                  >
                    <FileImage
                      :file="campaignPartner.file"
                      :alt="''"
                      :useBg="false"
                      effects="e_trim:30:transparent"
                    />
                  </div>
                </template>
              </slick>
            </div>
          </div>
        </section>
      </template>

      <!-- COMPROVANTES ######################## -->
      <template
        v-if="
          current == campaignLandingPageBlockIdentifier.DonationVoucherBlock
        "
      >
        <section
          ref="donationVoucher"
          :key="current"
          class="bloco voucher bg-theme-white p-0"
          data-title="Comprovante"
          style="padding: 100px 0"
          :class="{
            hidden: !campaignLandingPageConfig.showDonationVoucherBlock,
          }"
          v-if="editing || campaignLandingPageConfig.showDonationVoucherBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>

          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="
                goUp(campaignLandingPageBlockIdentifier.DonationVoucherBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.DonationVoucherBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showDonationVoucherBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.donationVoucherSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('donationVoucherSectionName', $event)"
            />
          </div>
          <div class="bloco-content">
            <DonationVoucher
              :editing="editing"
              :campaignLandingPageConfig="campaignLandingPageConfig"
              @updateProperty="updateProperty"
            ></DonationVoucher>
          </div>
        </section>
      </template>

      <!-- DOAÇÃO SIMPLIFICADA ######################## -->
      <template
        v-if="
          current == campaignLandingPageBlockIdentifier.SimplifiedDonationBlock
        "
      >
        <section
          ref="simplifiedCheckout"
          :key="current"
          class="bloco bloco-checkout-simplificado bg-theme-white px-0"
          data-title="Checkout Simplificado"
          style="padding: 100px 0"
          id="simplifiedDonationBlock"
          :class="{
            hidden: !campaignLandingPageConfig.showSimplifiedDonationBlock,
          }"
          v-if="
            editing || campaignLandingPageConfig.showSimplifiedDonationBlock
          "
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>

          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="
                goUp(campaignLandingPageBlockIdentifier.SimplifiedDonationBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(
                  campaignLandingPageBlockIdentifier.SimplifiedDonationBlock
                )
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showSimplifiedDonationBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.simplifiedDonationSectionName"
              class="input-title-camp"
              @blur="
                updatePropertyValue('simplifiedDonationSectionName', $event)
              "
            />
          </div>

          <!-- dropzone -->
          <div
            class="dropzone"
            :class="{ active: simplifiedDonationUploadImage }"
            v-if="editing"
          >
            <button
              class="close-dropzone"
              @click="
                simplifiedDonationUploadImage = !simplifiedDonationUploadImage
              "
            >
              <i class="fal fa-times"></i>
            </button>

            <!--active-->
            <input
              type="file"
              accept="image/*"
              @change="simplifiedDonationImageChanged"
            />
            <i class="fas fa-cloud-upload"></i>
            <h4>ARRASTE E SOLTE</h4>
            <p>
              uma imagem aqui, ou
              <span>procure</span>
            </p>
          </div>

          <!-- Overlay / Brightness / default opacity .2-->
          <div
            class="overlay"
            :style="{
              opacity:
                1 - campaignLandingPageConfig.simplifiedDonationOverlayOpacity,
            }"
          ></div>

          <!-- Image background / default opacity .5-->
          <div
            ref="simplifiedDonationImage"
            class="image-bg"
            :style="{
              backgroundImage: getImage(
                'simplifiedDonationFile',
                fileService_renderSimplifiedDonationFileCallback
              ),
              opacity: campaignLandingPageConfig.simplifiedDonationImageOpacity,
            }"
          ></div>
          <!-- ex: https://images.unsplash.com/photo-1469767902646-29456f6c43f8-->

          <!-- tools -->
          <div class="hero-tools right preview-off" v-if="editing">
            <!-- image opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showSimplifiedDonationImageOpacitySlider }"
            >
              <!--active-->
              <v-slider
                v-model="
                  campaignLandingPageConfig.simplifiedDonationImageOpacity
                "
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <!-- overlay opacity -->
            <div
              class="sub-box slider"
              :class="{ active: showSimplifiedDonationOverlayOpacitySlider }"
            >
              <v-slider
                v-model="
                  campaignLandingPageConfig.simplifiedDonationOverlayOpacity
                "
                min="0"
                max="1"
                step="0.1"
                thumb-label="always"
                color="#02ACB4"
                @change="save()"
              ></v-slider>
            </div>
            <a
              href
              :class="{ active: simplifiedDonationUploadImage }"
              @click.prevent="toggleSimplifiedDonationImageUpload()"
            >
              <i class="fal fa-image"></i>
            </a>
            <!--<a href>
          <i class="fal fa-palette"></i>
            </a>-->
            <a
              href
              @click.prevent="toggleSimplifiedDonationImageOpacity()"
              :class="{ active: showSimplifiedDonationImageOpacitySlider }"
            >
              <i class="custom-icon ci-contrast size-20"></i>
            </a>
            <a
              href
              @click.prevent="toggleSimplifiedDonationOverlayOpacity()"
              :class="{ active: showSimplifiedDonationOverlayOpacitySlider }"
            >
              <i class="custom-icon ci-brightness size-20"></i>
            </a>
          </div>

          <div class="bloco-content">
            <div align="center" class="spacing-title-landing">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block mb-0"
                @blur="updateProperty('simplifiedDonationTitle', $event)"
                v-html="campaignLandingPageConfig.simplifiedDonationTitle"
              ></h2>
            </div>
            <simplified-checkout
              :campaignLandingPageConfig="campaignLandingPageConfig"
              :landingPageOptions="landingPageOptions"
            />
          </div>
        </section>
      </template>

      <!-- PROJETOS VOLUNTARIADO ######################## -->
      <template
        v-if="current == campaignLandingPageBlockIdentifier.VolunteeringBlock"
      >
        <section
          ref="volunteeringBlock"
          :key="current"
          class="bloco px-0"
          data-title="Projetos de Voluntariado"
          id="volunteerBlock"
          style="padding: 100px 0"
          :class="{
            hidden: !campaignLandingPageConfig.showVolunteeringBlock,
          }"
          v-if="editing || campaignLandingPageConfig.showVolunteeringBlock"
        >
          <div v-if="false">
            <a href="javascript:;" class="colorPalette-toggle section-lp">
              <i class="fal fa-fill-drip"></i>
            </a>
          </div>

          <div class="edit" v-if="editing">
            <a
              href="javascript:;"
              class="fal fa-arrow-up"
              @click="
                goUp(campaignLandingPageBlockIdentifier.VolunteeringBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-arrow-down"
              @click="
                goDown(campaignLandingPageBlockIdentifier.VolunteeringBlock)
              "
            ></a>
            <a
              href="javascript:;"
              class="fal fa-eye-slash"
              @click="toggleHiddenClass('showVolunteeringBlock')"
            ></a>
            <input
              :value="campaignLandingPageConfig.volunteeringSectionName"
              class="input-title-camp"
              @blur="updatePropertyValue('volunteeringSectionName', $event)"
            />
          </div>

          <div class="bloco-content">
            <div align="center" class="spacing-title-landing">
              <h2
                :contenteditable="editing"
                spellcheck="false"
                data-title="Título"
                class="d-inline-block mb-0 volunteer-title"
                @blur="updateProperty('volunteeringTitle', $event)"
                v-html="campaignLandingPageConfig.volunteeringTitle"
              ></h2>
            </div>

            <!--SECTION Filtro doação-->
            <!-- NOTE Filtro -->
            <div
              style="display: flex; 
              align-self: center;
              border-radius: 3rem; 
              gap: .1rem; 
              overflow: hidden; 
              max-width: 61rem; 
              max-height: 4rem;
              margin:3rem auto;
              
              "
            >
              <div style="flex-basis: 100%;width: 0;">
                <select
                  style="background-color: #C2C6D7; 
                  border: none; 
                  border-radius: 0px; 
                  color: white; 
                  height: 4rem;
                  background-image: url(/images/chevron-down.svg);
                  background-repeat: no-repeat;
                  background-position: right 1rem center;
                  background-size: 1rem auto;
                  
                  "
                  name="selectPlace"
                  id="selectPlace"
                  @change="filterVolunteer($event)"
                >
                  <option value="" selected>Cidade</option>

                  <option
                    v-for="(filter, index) in volunteerFilters.places"
                    :value="filter"
                    :key="'filterVolunteerPlaces' + index"
                  >
                    {{ filter }}
                  </option>
                </select>
              </div>

              <div style="flex-basis: 100%;width: 0;">
                <select
                  style="background-color: #C2C6D7; 
                  border: none; 
                  border-radius: 0px; 
                  color: white; 
                  height: 4rem;
                  background-image: url(/images/chevron-down.svg);
                  background-repeat: no-repeat;
                  background-position: right 1rem center;
                  background-size: 1rem auto;
                  "
                  name="typeSubscription"
                  id="typeSubscription"
                  @change="filterVolunteer($event)"
                >
                  <option value="" selected>Tipo da vaga</option>
                  <option
                    v-for="(filter, index) in volunteerFilters.types"
                    :value="filter"
                    :key="'filterVolunteerTypes' + index"
                  >
                    {{ filter }}
                  </option>
                </select>
              </div>

              <div style="flex-basis: 100%;width: 0;">
                <select
                  style="
                  background-color: #0079BA; 
                  border: none; 
                  border-radius: 0px; 
                  color: white; 
                  height: 4rem;
                  background-image: url(/images/chevron-down.svg);
                  background-repeat: no-repeat;
                  background-position: right 1rem center;
                  background-size: 1rem auto;
                  "
                  name="institutionName"
                  id="institutionName"
                  @change="filterVolunteer($event)"
                >
                  <option value="">Nome da instituição</option>
                  <option
                    v-for="(filter, index) in volunteerFilters.names"
                    :value="filter"
                    :key="'filterVolunteerNames' + index"
                  >
                    {{ filter }}
                  </option>
                </select>
              </div>
            </div>
            <!-- !SECTION -->
            <!-- <v-layout row wrap w-100>
              <div class="filter-modify">
                  <div class="display-flex">
                    <InputSelect
                      :items="causeList"
                      valueAttribute="id"
                      textAttribute="name"
                      v-model="selectedCause"
                      @input="searchProjects()"
                      unselectedText="Todos"
                      placeholderText="Causa"
                      :useAnotherDefaultValue="true"
                      class="left-select"
                    ></InputSelect>
                    <InputSelect
                      :items="coverageList"
                      valueAttribute="name"
                      textAttribute="name"
                      v-model="selectedCoverage"
                      @input="searchProjects()"
                      unselectedText="Todos"
                      placeholderText="Abrangência"
                      :useAnotherDefaultValue="true"
                      class="right-select"
                    ></InputSelect>
                  </div>
                  <div class="display-flex justify-center">
                    <div class="count-projects">
                      {{ volunteeringAttributeOffers.length }} /
                      {{ volunteeringAttributeOffers.length }}
                    </div>
                  </div>
                </div> 
            </v-layout>-->
            <a
              href="javascript:document.getElementById('volunteeringWrapper').scrollBy(454, 0);"
              class="scroll-arrow next proj-next"
            >
              <Icon name="far fa-chevron-right" size="16" />
            </a>
            <a
              href="javascript:document.getElementById('volunteeringWrapper').scrollBy(-454, 0);"
              class="scroll-arrow prev proj-prev"
              style="display: none"
            >
              <Icon name="far fa-chevron-left" size="16" />
            </a>
            <div
              class="project-landing slider-h wrapper volunteering"
              :class="verifyWindows() ? 'no-scroll' : ''"
              ref="volunteeringSlick"
              id="volunteeringWrapper"
              @scroll="slickOnScroll"
              :key="volunteeringCardsKey"
            >       
                  <template class="slider-h wrapper">
                  <div
                    v-for="attributeOffer in volunteeringAttributeOffers"
                    :key="attributeOffer.id"
                    class="item volunteer-card"
                  >
                    <div
                      class="projeto fill-height"
                      style=" box-shadow: #00000029 0.1em 0.1em 0.9em; border-radius: 1em;"
                    >
                      <CardAttributeOfferCampaign
                        :attributeOffer="attributeOffer"
                        :volunteeringAttributeOfferId="attributeOffer.id"
                        :VolunteerSlot="VolunteerSlot"
                        :multipleSlot="campaignLandingPageConfig.campaign.subscribeMoreThanOneTime"
                        :hasSubscribedOnVolunteerSlot="
                          hasSubscribedOnVolunteerSlot
                        "
                        @selectOffer="openModalAttributeOfferSubscribe"
                      />
                    </div>
                  </div>
                </template>    
            </div>
          </div>
          <ModalAttributeOfferSubscribe
            :info="infoCard"
              :attributeOffer="volunteeringAttributeOfferSelected"
              :registrationOffers="volunteeringUserRegistrationOffers"
              :volunteering="volunteeringAttributeOfferSelected.volunteering"
              :dialog="volunteeringDialog"
              :isDeleted="isDeleted"
              @close="volunteeringDialog = false;"
            />
        </section>
      </template>
    </template>

    <!-- COMEÇO LISTA DE PROJETOS -->
    <template v-if="dialogProjects">
      <v-dialog v-model="dialogProjects" scrollable max-width="900px">
        <v-card>
          <v-card-title class="headline">
            <h2
              class="mt-3 mb-2 text-xs-center f-size-16 color-theme-texto font-destaque"
              v-if="appreciationSelected.name"
            >
              {{ appreciationSelected.name }} -&nbsp;
            </h2>
            <h2
              class="mt-3 mb-2 text-xs-center f-size-16 color-theme-texto font-destaque"
              v-if="appreciationSelected.name"
            >
              {{ appreciationSelected.name }} -&nbsp;
            </h2>
            <h2
              v-if="
                appreciationSelected.firstValue == null ||
                  appreciationSelected.firstValue == ''
              "
              class="mt-3 mb-2 text-xs-center f-size-16 color-theme-texto font-destaque"
            >
              Até R${{
                formatterHelper.formatMoney(appreciationSelected.lastValue)
              }}
            </h2>
            <h2
              v-else-if="
                appreciationSelected.lastValue == null ||
                  appreciationSelected.lastValue == ''
              "
              class="mt-3 mb-2 text-xs-center f-size-16 color-theme-texto font-destaque"
            >
              Mais de R${{
                formatterHelper.formatMoney(appreciationSelected.firstValue)
              }}
            </h2>
            <h2
              v-else
              class="mt-3 mb-2 text-xs-center f-size-16 color-theme-texto font-destaque"
            >
              De R${{
                formatterHelper.formatMoney(appreciationSelected.firstValue)
              }}
              até R${{
                formatterHelper.formatMoney(appreciationSelected.lastValue)
              }}
            </h2>
            <v-btn class="close-modal" @click="closeModalProjects()">
              <i class="fal fa-times"></i>
            </v-btn>
          </v-card-title>
          <v-card-text style="height: 550px;" class="bg-theme-fundo">
            <div class="list-scroll donation-list">
              <template>
                <div class="container-fluid w-100 mt-5 aux-cfluid">
                  <v-flex
                    display-flex
                    flex-column
                    mb-3
                    align-center
                    justify-center
                  >
                    <h3
                      class="mt-3 mb-2 text-xs-center f-size-28 color-theme-texto font-destaque"
                    >
                      Escolha o projeto
                    </h3>
                  </v-flex>
                  <div class="wrapper pb-3">
                    <v-layout wrap display-flex justify-center>
                      <template
                        v-for="donationProduct in campaignAppreciationDonationproducts"
                      >
                        <div
                          class="box-donation-product"
                          :key="donationProduct.project.id"
                        >
                          <FileImage
                            :file="
                              donationProduct.project.projectLandingPageConfig
                                .heroFile
                            "
                          />
                          <h2
                            id="project-name-appreciation"
                            class="color-theme-primaria mb-4"
                          >
                            {{ donationProduct.project.shortName }}
                          </h2>
                          <!-- <p :title="donationProduct.project.objective">
                            {{commonHelper.getSubstring(donationProduct.project.objective, 170)}}
                          </p>           -->
                          <div>
                            <!-- AQUI ESTA O BOTÃO NÃO EXISTE showDonationButton -->
                            <button
                              v-if="true"
                              type="button"
                              :class="
                                alreadyExistsInCart({
                                  project: donationProduct.project,
                                  donationProduct: donationProduct,
                                })
                                  ? 'with-check'
                                  : ''
                              "
                              class="bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                              @click="
                                addProjectFromModalAppreciationDonationproduct(
                                  donationProduct
                                )
                              "
                            >
                              <Icon
                                name="fas fa-check"
                                class="check-circle"
                                size="18"
                                colorFont="#02ACB4"
                              ></Icon>
                              Quero doar
                            </button>
                          </div>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <!-- FIM LISTA DE PROJETOS -->

    <!-- FOOTER LOGO/MENU  ######################### -->
    <section class="footer bg-theme-fundo">
      <v-layout class="container row align-center footer-divider">
        <v-flex>
          <span class="mb-2 f-size-11 color-theme-texto" style="opacity: 0.7"
            >Realização:</span
          >
          <div class="logo">
            <!-- <input type="file" title="Clique para fazer upload"> -->
            <!-- <img :src="getCompanyImage()" alt /> -->
            <div
              class="logo"
              :style="{ backgroundImage: getCompanyImage() }"
            ></div>
            <!-- ../../assets/images/Acme_Logo.svg-->
          </div>
        </v-flex>
        <v-flex text-xs-right menu-footer>
          <a onclick="document.getElementById('showContact').click()"
            >CONTATO</a
          >
          |
          <a
            @click="$gtag('send', 'event', 'FAQ', 'FAQ', 'FAQ')"
            onclick="document.getElementById('showFaq').click()"
            >FAQ</a
          >
        </v-flex>
      </v-layout>
    </section>

    <section class="footer pb-4 bg-theme-white">
      <div class="container">
        <v-layout align-center wrap row>
          <v-flex xs12 md6 mb-4>
            <!-- campaignLandingPageConfig.copyrightText -->
            <!-- {{this.campaignLandingPageConfig.campaign.company}} -->
            <span
              class="f-size-12"
              spellcheck="false"
              data-title="Copyright"
              @blur="updateProperty('copyrightText', $event)"
              v-html="
                this.campaignLandingPageConfig.campaign.company.entity
                  .socialReason +
                  ' © ' +
                  getYear() +
                  ' - Todos os direitos reservados'
              "
            ></span>
          </v-flex>
          <v-flex xs12 md6 text-xs-right>
            <div class="copy-CC">
              <a
                rel="license"
                href="http://creativecommons.org/licenses/by-nc-nd/4.0/deed.pt_BR"
                target="_blank"
                style="font-family: Nunito, sans-serif; text-size-adjust: auto"
              >
                <img
                  alt="Licença Creative Commons"
                  align="right"
                  src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
                  vspace="4"
                  style="border-width: 0px"
                  class="ml-3"
                />
              </a>
              <span>Este site está licenciado com uma Licença</span>
              <a
                rel="license"
                href="http://creativecommons.org/licenses/by-nc-nd/4.0/deed.pt_BR"
                target="_blank"
              >
                Creative Commons
                <br />Atribuição - Não Comercial - Sem Derivações 4.0
                Internacional.
              </a>
            </div>
          </v-flex>
          <!--<v-flex>
            <div class="social" align="right">
              <a href class="fab fa-facebook-square"></a>
              <a href class="fab fa-instagram"></a>
            </div>
          </v-flex>-->
        </v-layout>

        <v-layout notes>
          <v-flex>
            <div
              :contenteditable="editing"
              spellcheck="false"
              data-title="Notas de Rodapé"
              @blur="updateProperty('footnotes', $event)"
              v-html="campaignLandingPageConfig.footnotes"
              @click="
                $gtag('send', 'event', 'Footer', this.textContent, 'Link_Text')
              "
            ></div>
          </v-flex>
        </v-layout>
      </div>
    </section>
    <section class="bg-theme-primaria">
      <div class="container py-5">
        <v-layout row justify-space-between powered-by>
          <v-flex color-theme-white t-italic f-size-11>
            Powered by
            <span>Abrace uma Causa</span>
          </v-flex>
          <v-flex t-right color-theme-white t-italic f-size-11>
            <a
              href="javascript:;"
              class="color-theme-white"
              @click="
                showServiceTerms = !showServiceTerms;
                $gtag('send', 'event', 'Footer', 'Termos de Uso', 'Link_Text');
              "
              >Termos de uso</a
            >
            <span class="mx-2">|</span>
            <a
              href="javascript:;"
              class="color-theme-white"
              @click="
                showPolicyTerms = !showPolicyTerms;
                $gtag(
                  'send',
                  'event',
                  'Footer',
                  'Política de Privacidade',
                  'Link_Text'
                );
              "
              >Política de Privacidade</a
            >
          </v-flex>
        </v-layout>
      </div>
    </section>
    <!-- <AucAlert
      ref="confirmDonationComponent"
      title="Você deseja finalizar a doação ou selecionar mais projetos?"
      confirmButtonText="Finalizar"
      cancelButtonText="Continuar escolhendo"
      @confirm="gotoCart()"
    />-->
    <!-- {{campaignLandingPageConfig.cssInjection}} -->
    <!-- <style id="cssInjection"></style> -->
    <v-flex class="p-relative" v-if="editing" v-show="false">
      <button
        class="mx-2 btn-chat-bot fab dark"
        @click="showModalChatBot = !showModalChatBot"
        color="cyan"
      >
        <Icon name="fas fa-comment-alt-plus" size="16" colorFont="#fff" />
      </button>
    </v-flex>
    <component :is="'style'" type="text/css">
      {{ campaignLandingPageConfig.cssInjection }}
    </component>
    <component :is="'script'" type="application/javascript">
      {{ campaignLandingPageConfig.jsInjection }}
    </component>
    <ModalReadMoreTestimony ref="readMoreTestimony" />
    <ModalCookies ref="cookiesModal" />
  </div>
</template>
<script type="plain/text">
// Documentação: https://github.com/xiaokaike/vue-color
import DonationVoucher from "@/components/DonationVoucher.vue";
import ColorPalette from "@/components/ColorPalette.vue";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import InfoProjectCard from "@/components/InfoProjectCard.vue";
import LinkVideo from "@/components/LinkVideo.vue";
import DonationProductsList from "@/components/DonationProductsList.vue";
import DonationStepEdit from "@/components/DonationStepEdit.vue";
import AppreciationEdit from "@/components/AppreciationEdit.vue";
import NewsEdit from "@/components/NewsEdit.vue";
import CasesEdit from "@/components/CasesEdit.vue";
import PhotoCardsEdit from "@/components/PhotoCardsEdit.vue";
import GalleryDonation from "@/components/GalleryDonation.vue";
import Testimony from "@/components/Testimony.vue";
import Partners from "@/components/Partners.vue";
import DonationProducts from "@/components/DonationProducts.vue";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
// import CampaignAppreciationDonationProduct from "@/scripts/models/campaignAppreciationDonationProduct.model";
import CartItem from "@/scripts/models/cartItem.model";
import CampaignService from "@/scripts/services/campaign.service";
import FileService from "@/scripts/services/file.service";
import VolunteeringService from "@/scripts/services/volunteering.service";
import { GetProjectTypeName, ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import CampaignLandingPageBlockIdentifier from "@/scripts/models/enums/campaignLandingPageBlockIdentifier.enum";
import FileImage from "@/components/FileImage.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { Causes } from "@/scripts/models/enums/causes.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";
import { flat } from "@/scripts/helpers/list.helper";
import TokenService from "@/scripts/services/token.service";
import ImageCompressor from "image-compressor.js";
import ModalNews from "./modal/ModalNews.vue";
import ModalReadMoreTestimony from "./modal/ModalReadMoreTestimony.vue";
import ModalCookies from "./modal/ModalCookies.vue";
import ModalChatBot from "@/components/Modal.vue";
import ModalTemplate from "@/components/Modal.vue";
import CssEditor from "@/components/CssEditor.vue";
import CardAttributeOfferCampaign from "@/components/CardAttributeOfferCampaign.vue";
import ModalAttributeOfferSubscribe from "@/views/app/campaign/modal/ModalAttributeOfferSubscribe.vue";
import AucAlert from "@/components/AucAlert.vue";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import PersonService from "@/scripts/services/person.service.js"
import CampaignAppreciationDonationProductService from "@/scripts/services/campaignAppreciationDonationProduct.service";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import { mapGetters, mapActions } from "vuex";
import SimplifiedCheckout from "./simplifiedCheckout/SimplifiedCheckout.vue";
import PaymentMethod from "../../../scripts/models/enums/paymentMethod.enum";
import CartService from "@/scripts/services/cart.service";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";

export default {
  components: {
    CardAttributeOfferCampaign,
    ModalAttributeOfferSubscribe,
    ColorPalette,
    DonationProductsList,
    DonationStepEdit,
    AppreciationEdit,
    NewsEdit,
    PrivacyPolicyTermsModal,
    DonationVoucher,
    UseTermsModal,
    ModalTemplate,
    CasesEdit,
    PhotoCardsEdit,
    GalleryDonation,
    Testimony,
    InfoProjectCard,
    Partners,
    LinkVideo,
    FileImage,
    DonationProducts,
    ModalNews,
    ModalReadMoreTestimony,
    ModalCookies,
    CssEditor,
    ModalChatBot,
    AucAlert,
    SimplifiedCheckout,
  },

  //propriedades do filtro
  props: {
    landingPageOptions: null,
    textCidade: {
      type: String,
      default: 'Cidade',
    },
    texttipovaga: {
      type: String,
      default: 'Tipo de vaga',
    },
    textinstituicao: {
      type: String,
      default: 'Nome da instituição',
    },
  },
  data() {
    return {
      campaignLandingPageBlockIdentifier: CampaignLandingPageBlockIdentifier,
      agreeCookieVar: false,
      cartService: new CartService(),
      campaignAppreciationDonationproducts:[],
      dialogNews: false,
      dialogProjects: false,
      dialogEditor: false,
      loadedProjectImageCount: 0,
      howManyProjects: 12,
      filteredProjects: [],
      showPolicyTerms: false,
      showServiceTerms: false,
      campaignPaused: false,
      causeList: [],
      coverageList: [],
      selectedCause: null,
      selectedCoverage: null,
      showModalChatBot: false,
      showModalTemplate: false,
      commonHelper: CommonHelper,
      totalOffers: 0,
      totalFreeOffers: 0,
      totalSubscribers: 0,
      offersPercentage: 0,
      volunteerData: [],
      volunteeringAttributeOffersBackup: null,
      infoCard: null,
      volunteerFilters: {
        places: [],
        names: [],
        types: ['Presencial', 'A Distância'],
      },
      volunteerFiltersSelected: {
        places: [],
        names: [],
        types: [],
      },
      volunteerFiltersIds: [],
      //   blocks: [],
      ProjectTypes: ProjectTypes,
      campaignService: new CampaignService(),
      fileService: new FileService(),
      volunteeringService: new VolunteeringService(),
      volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
      campaignAppreciationDonationProductService: new CampaignAppreciationDonationProductService(),
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      personService: new PersonService(),
      volunteeringUserRegistrationOffers: [],
      volunteeringAttributeOfferSelected: {},
      volunteeringDialog: false,
      volunteeringAttributeOffers: [],
      saveQueue: [],
      saving: false,
      campaignLandingPageConfig: new CampaignLandingPageConfig(),
      formatterHelper: new FormatterHelper(),
      heroPreview: false,
      heroUploadImage: false,
      simulationUploadImage: false,
      memberGetMemberUploadImage: false,
      simplifiedDonationUploadImage: false,
      videoUploadImage: false,
      watchVideo: false,
      testimonyUploadImage: false,
      canShow: true,
      coinSelected: "R$ ",
      capturedValue: null,
      objetiveValue: null,
      percentualValue: null,
      dolarPrice: null,
      modalNewsObj: null,
      selectedProject: null,
      appreciationSelected: null,
      checkProjectType: null,
      cssVariableToPropertyMapping: {
        "--theme-primaria": "themePrimaria",
        "--theme-secundaria": "themeSecundaria",
        "--theme-destaque": "themeDestaque",
        "--theme-fundo": "themeFundo",
        "--theme-padrao": "themePadrao",
        "--theme-gradiente-start": "themeGradienteStart",
        "--theme-gradiente-end": "themeGradienteEnd",
        "--theme-texto": "themeTexto",
        "--font-principal": "fontPrincipal",
        "--font-destaques": "fontDestaques",
      },
      cssVariableNames: [
        "--theme-primaria",
        "--theme-secundaria",
        "--theme-destaque",
        "--theme-fundo",
        "--theme-padrao",
        "--theme-gradiente-start",
        "--theme-gradiente-end",
        "--theme-texto",
        "--font-principal",
        "--font-destaques",
      ],
      themeColors: null,
      fileObject: null,
      toggleFunction: null,
      imageComponent: null,
      showHeroImageOpacitySlider: false,
      showHeroOverlayOpacitySlider: false,
      showSimulationImageOpacitySlider: false,
      showMemberGetMemberImageOpacitySlider: false,
      showSimulationOverlayOpacitySlider: false,
      showMemberGetMemberOverlayOpacitySlider: false,
      showSimplifiedDonationImageOpacitySlider: false,
      showSimplifiedDonationOverlayOpacitySlider: false,
      showVideoImageOpacitySlider: false,
      showVideoOverlayOpacitySlider: false,
      showTestimonyImageOpacitySlider: false,
      showTestimonyOverlayOpacitySlider: false,
      showAllNews: false,
      loadingInstitutionImage: false,
      loadingCompanyImage: false,
      galleryLightboxVisible: false,
      galleryLightboxIndex: 0,
      partnerLightboxVisible: false,
      partnerLightboxIndex: 0,
      projectsSlickReInitiated: false,
      apiCalls: -1,
      userAlreadyHasRegistrations: false,
      slickOptionsProject: {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 2400,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1060,
            settings: {
              // arrows: false,
              slidesToShow: 1,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 5000,
            },
          },
        ],
      },
      slickOptionsCases: {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: "linear",
        autoplay: true,
        autoplaySpeed: 7000,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              centerMode: true,
              centerPadding: "30px",
              arrows: false,
              fade: false,
            },
          },
        ],
      },
      slickOptionsStep: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1540,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsPhotoCard: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1540,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsGrats: {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1540,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsPartner: {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              arrows: false,
            },
          },
          {
            breakpoint: 730,
            settings: {
              // arrows: false,
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      },

      //proprieties off filter button



      slickOptionsDepoimento: {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              arrows: false,
            },
          },
          {
            breakpoint: 730,
            settings: {
              // arrows: false,
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      },
      slickOptionsAgrad: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        prevArrow: "#agradPrevArrow",
        nextArrow: "#agradNextArrow",
        responsive: [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              centerMode: true,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 900,
            settings: {
              centerMode: true,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 730,
            settings: {
              centerMode: true,
              slidesToShow: 1,
            },
          },
        ],
      },
      chatbots: [
        { type: 1, appId: "SnatchBot", logo: "/assets/images/icon-snatch.png" },
        { type: 2, appId: "Drift", logo: "/assets/images/icon-drift.png" },
        {
          type: 3,
          appId: "Manychat",
          logo: "/assets/images/icon-manychat.png",
        },
      ],
      hasSubscribedOnVolunteerSlot: false,
      VolunteerSlot: [],
      modalOffer: 0,
      entityId: null,
      userInformation: null,
      volunteeringCardsKey: 0
    };
  },
  watch: {
    "campaignLandingPageConfig.campaignPartnerList": {
      handler: function (after, before) {
        this.reInitSlick(this.$refs.partnersSlick);
      },
      deep: true,
    },
    "campaignLandingPageConfig.campaignCaseList": {
      handler: function (after, before) {
        this.reInitSlick(this.$refs.casesSlick);
      },
      deep: true,
    },
    "campaignLandingPageConfig.campaignDonationStepList": {
      handler: function (after, before) {
        this.reInitSlick(this.$refs.stepSlick);
      },
      deep: true,
    },
    "campaignLandingPageConfig.projectList": {
      handler: function (after, before) {
        this.projectsSlickReInitiated = false;
      },
      deep: true,
    },
    campaignLandingPageConfig: {
      handler: function (after, before) {
        if (this.$route.query.scrollTo) {
          if (this.scrollMeTo(this.$route.query.scrollTo))
            this.$route.query.scrollTo = null;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.campaignLandingPageConfig = this.$route.query.campaignLandingPageConfig
    this.checkProjectType = this.$route.query.campaignLandingPageConfig.campaign.campaignTypeId
    this.buildThemeColors();
    await this.getUserInformation();
    this.agreeCookieVar = localStorage.getItem("cookie-agree");
    if(this.$route.query.indicador){
      localStorage.setItem("indication_code", this.$route.query.indicador)
    }
    if (CommonHelper.getSubdomain() || this.editing) {
      console.log(this.$route.query.campaignLandingPageConfig)
      this.campaignLandingPageConfig =
        this.$route.query.campaignLandingPageConfig;
        
      this.campaignLandingPageConfig.projectList.map(e=>{
        // e.donationProductList.map(ee=> ee.radio = false)
      })
      this.campaignPaused =
        this.campaignLandingPageConfig.campaign.campaignPhaseId == CampaignPhases.Pausada ||
        !this.campaignLandingPageConfig.campaign.published;

      this.$emit("updateCampaign", this.campaignLandingPageConfig.campaign);
      var currentPhase = this.campaignLandingPageConfig.campaign.campaignPhaseId;
      var campaingUnAvailable = ((currentPhase == CampaignPhases.EmAndamento && !this.campaignLandingPageConfig.campaign.published) ||
        currentPhase == CampaignPhases.Encerrada ||
        currentPhase == CampaignPhases.Cancelada);

      if (campaingUnAvailable && this.$route.query.preview == null && !this.editing) {
        this.$router.push({ name: Routes.NotFound });
      }
      if (
        this.campaignLandingPageConfig &&
        this.campaignLandingPageConfig.campaign &&
        this.campaignLandingPageConfig.campaign.file
      ) {
        this.$set(
          this.landingPageOptions,
          "logo",
          this.campaignLandingPageConfig.campaign.file
        );
        this.$set(
          this.landingPageOptions,
          "showCallToActioOnNav",
          this.campaignLandingPageConfig.showCallToActioOnNav
        );
        this.$set(
          this.landingPageOptions,
          "titleCallToActioOnNav",
          this.campaignLandingPageConfig.titleCallToActioOnNav
        );

        //this.$store.commit("INIT_CAMPAIGN_LANDINGPAGE_CONFIG", this.campaignLandingPageConfig);
        // Chumbei temporariamente para iv- mas por enquanto finja que nao viu isso
        if (this.campaignLandingPageConfig.campaign.id == 9) {
          if (typeof Init === "function") {
            Init(
              "?botID=30501&appID=",
              600,
              600,
              "https://dvgpba5hywmpo.cloudfront.net/media/image/TYsW63oOYPxbyocUIdDliFOrh",
              "bubble",
              "#00AFF0",
              90,
              90,
              62.99999999999999,
              "",
              "1",
              "#FFFFFF",
              0
            );
          }
        } else if (
          this.campaignLandingPageConfig.campaign &&
          this.campaignLandingPageConfig.campaign.id == 10
        ) {
          if (typeof Init === "function") {
            Init(
              "?botID=31343&appID=webchat",
              600,
              600,
              "https://dvgpba5hywmpo.cloudfront.net/media/image/3VORB6g4yeFLOb05BNGwG9fly",
              "bubble",
              "#000000",
              90,
              90,
              62.99999999999999,
              "",
              "1",
              "#FFFFFF",
              0
            );
          }
        }
      }
      this.landingPageOptions.showMenu = !this.editing;
      //this.buildBlocksAnchors();
      if (this.$route.query.campaignId == null) {
        this.apiCalls = 1
        this.campaignService
          .getCampaignCaseList(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_getCampaignCaseListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignPhotoCardsList(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_getCampaignPhotoCardsListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignDonationStepList(
            this.campaignLandingPageConfig.campaign.id
          )
          .then(this.campaignService_getCampaignDonationStepListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignAppreciationList(
            this.campaignLandingPageConfig.campaign.id
          )
          .then(this.campaignService_getCampaignAppreciationListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignImageList(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_getCampaignImageListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignNewsList(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_getCampaignNewsListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignPartnerList(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_getCampaignPartnerListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .getCampaignTestimonyList(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_getCampaignTestimonyListCallback)
          .then(() => this.apiCalls--);
        this.apiCalls++
        this.campaignService
          .calculateCampaignValues(this.campaignLandingPageConfig.campaign.id)
          .then(this.campaignService_calculateCampaignValuesCallback)
          .then(() => this.apiCalls--);
        this.searchProjects();
      } else {
        this.fillCausesAndCoveragesList(
          this.campaignLandingPageConfig.projectList
        );
        this.searchProjects();
      }
    } else {
      var url = location.protocol + "//" + "doe." + location.host;
      location.href = url;
      // this.$router.push({ name: Routes.web.Login });
    }
    this.setSimplifiedCheckout(
      this.campaignLandingPageConfig.showSimplifiedDonationBlock
    );
    await this.loadVolunteering();
  },
  mounted() {
    var url = window.location.href;
    if (url.indexOf("/contato") != -1) {
      document.getElementById("showContact").click();
    }
    if (url.indexOf("/politica-de-privacidade") != -1) {
      this.showPolicyTerms = true;
    }

    setTimeout(() => {
      this.urlHashsActions();
    }, 2000);
  },
  beforeRouteEnter: (to, from, next) => {
    if (CommonHelper.getSubdomain() == "www.lamffirpf")
      window.location.href = "https://lamffirpf.abraceumacausa.com.br";
    if (CommonHelper.getSubdomain() == "defto")
      window.location.href = "https://palmasparaoamor.abraceumacausa.com.br";
    if (
      !CommonHelper.getSubdomain() &&
      to.name != Routes.app.CampaignLandingPageEdit
    ) {
      var url = location.protocol + "//" + "doe." + location.host;
      location.href = url;
      //next({ name: Routes.web.Login });
      return;
    }
    let campaignService = new CampaignService();
    if (to.query.campaignId == null) {
      campaignService
        .getCampaignLandingPageConfigBySubdomain()
        .then(function (data) {
          data.blockOrderArray = JSON.parse(data.blockOrder);
          to.query.campaignLandingPageConfig = data;
          
          
          if (data.heroFileId) {
            data.heroFile.id = data.heroFileId;
          }
          if (data.simulationFileId) {
            data.simulationFile.id = data.simulationFileId;
          }
          if(data.memberGetMemberFileId){
            data.memberGetMemberFile.id = data.memberGetMemberFileId
          }
          if (data.videoFileId) {
            data.videoFile.id = data.videoFileId;
          }
          if (data.testimonyFileId) {
            data.testimonyFile.id = data.testimonyFileId;
          }
          if (data.simplifiedDonationFileId) {
            data.simplifiedDonationFile.id = data.simplifiedDonationFileId;
          }
          if (data.campaign.company.fileId) {
            data.campaign.company.file = {
              id: data.campaign.company.fileId,
            };
          }
          next();
        });
    } else {
        if (to.query.withoutCarts == 'true') {
          campaignService
          .getCampaignLandingPageConfigByCampaignIdWithoutCarts(to.query.campaignId)
          .then(function (data) {
            data.blockOrderArray = JSON.parse(data.blockOrder);
            to.query.campaignLandingPageConfig = data;
            next();
          });
        }
        else{
          campaignService
          .getCampaignLandingPageConfigByCampaignId(to.query.campaignId)
          .then(function (data) {
            data.blockOrderArray = JSON.parse(data.blockOrder);
            to.query.campaignLandingPageConfig = data;
            next();
          });
        }
    }
  },
  computed: {
    ...mapGetters(["cartItems", "alreadyExistsInCart", "logged"]),
    isMiddle() {
      const arr = this.campaignLandingPageConfig.campaignAppreciationList
      return Math.floor((arr.length - 1) / 2);
    },
    editing() {
      return this.$route.name == Routes.app.CampaignLandingPageEdit;
    },
    ticketsAvailable() {
      if (
        this.campaignLandingPageConfig &&
        this.campaignLandingPageConfig.projectList
      ) {
        var projects = this.campaignLandingPageConfig.projectList.filter(
          (project) => project.projectTypeId == ProjectTypes.Ticket
        );
        if (projects.length > 0) {
          var donationProduct = projects.filter(
            (project) => project.donationProductList.length > 0
          );
          return donationProduct.length == 0 ? true : false;
        }
      }
      return false;
    },
    // Function to return components and make to appear in navbar
    anchors() {
      let blocks = [];
      for (
        let i = 0;
        i < this.campaignLandingPageConfig.blockOrderArray.length;
        i++
      ) {
        let current = this.campaignLandingPageConfig.blockOrderArray[i];
        if (current == CampaignLandingPageBlockIdentifier.NumberBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.NumberBlock,
            name: "numbersBlock",
            text: this.campaignLandingPageConfig.numberSectionName,
            show: this.campaignLandingPageConfig.showNumberBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.ProjectBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.ProjectBlock,
            name: "projectsBlock",
            text: this.campaignLandingPageConfig.projectsSectionName,
            show:
              this.campaignLandingPageConfig.projectsCount > 1 &&
              this.campaignLandingPageConfig.showProjectsBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.CaseBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.CaseBlock,
            name: "casesBlock",
            text: this.campaignLandingPageConfig.casesSectionName,
            show: this.campaignLandingPageConfig.showCasesBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.StepBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.StepBlock,
            name: "donationStepsBlock",
            text: this.campaignLandingPageConfig.donationStepsSectionName,
            show: this.campaignLandingPageConfig.showDonationStepsBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.SimulationBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.SimulationBlock,
            name: "simulationBlock",
            text: this.campaignLandingPageConfig.simulationSectionName,
            show: this.campaignLandingPageConfig.showSimulationBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.NewsBlock) {
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.NewsBlock,
            name: "newsBlock",
            text: this.campaignLandingPageConfig.newsSectionName,
            show: this.campaignLandingPageConfig.showNewsBlock,
            click: this.scrollMeTo.bind(this),
          });
        }
        else if (
          current == CampaignLandingPageBlockIdentifier.AppreciationBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.AppreciationBlock,
            name: "appreciationBlock",
            text: this.campaignLandingPageConfig.appreciationSectionName,
            show: this.campaignLandingPageConfig.showAppreciationBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.TestimonyBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.TestimonyBlock,
            name: "testimonyBlock",
            text: this.campaignLandingPageConfig.testimonySectionName,
            show: this.campaignLandingPageConfig.showTestimonyBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.VideoBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.VideoBlock,
            name: "videoBlock",
            text: this.campaignLandingPageConfig.videoSectionName,
            show: this.campaignLandingPageConfig.showVideoBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (
          current == CampaignLandingPageBlockIdentifier.PhotoGalleryBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.PhotoGalleryBlock,
            name: "photoGalleryBlock",
            text: this.campaignLandingPageConfig.photoGallerySectionName,
            show: this.campaignLandingPageConfig.showPhotoGalleryBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (current == CampaignLandingPageBlockIdentifier.PartnerBlock)
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.PartnerBlock,
            name: "partnerBlock",
            text: this.campaignLandingPageConfig.partnerSectionName,
            show: this.campaignLandingPageConfig.showPartnerBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (
          current == CampaignLandingPageBlockIdentifier.DonationVoucherBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.DonationVoucherBlock,
            name: "donationVoucher",
            text: this.campaignLandingPageConfig.donationVoucherSectionName,
            show: this.campaignLandingPageConfig.showDonationVoucherBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (
          current == CampaignLandingPageBlockIdentifier.SimplifiedDonationBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.SimplifiedDonationBlock,
            name: "simplifiedCheckout",
            text: this.campaignLandingPageConfig.simplifiedDonationSectionName,
            show: this.campaignLandingPageConfig.showSimplifiedDonationBlock,
            click: this.scrollMeTo.bind(this),
          });
          else if (
          current == CampaignLandingPageBlockIdentifier.VolunteeringBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.VolunteeringBlock,
            name: "volunteeringBlock",
            text: this.campaignLandingPageConfig.volunteeringSectionName,
            show: this.campaignLandingPageConfig.showVolunteeringBlock,
            click: this.scrollMeTo.bind(this),
          });
          else if (
          current == CampaignLandingPageBlockIdentifier.PhotoCardsBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.PhotoCardsBlock,
            name: "photoCardsBlock",
            text: this.campaignLandingPageConfig.photoCardsSectionName,
            show: this.campaignLandingPageConfig.showPhotoCardsBlock,
            click: this.scrollMeTo.bind(this),
          });
        else if (
          current == CampaignLandingPageBlockIdentifier.EmptyBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.EmptyBlock,
            name: "emptyBlock",
            text: this.campaignLandingPageConfig.emptyBlockSectionName,
            show: this.campaignLandingPageConfig.showEmptyBlock,
            click: this.scrollMeTo.bind(this),
          });
       else if (
          current == CampaignLandingPageBlockIdentifier.DonationProductsBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.DonationProductsBlock,
            name: "donationProductsBlock",
            text: this.campaignLandingPageConfig.donationProductsSectionName,
            show: this.campaignLandingPageConfig.showDonationProductsBlock,
            click: this.scrollMeTo.bind(this),
          });
       else if (
          current == CampaignLandingPageBlockIdentifier.MemberGetMemberBlock
        )
          blocks.push({
            number: CampaignLandingPageBlockIdentifier.MemberGetMemberBlock,
            name: "memberGetMemberBlock",
            text: "Indicação",
            show: this.campaignLandingPageConfig.showMemberGetMemberBlock,
            click: this.scrollMeTo.bind(this)
          });
      }
      blocks = blocks.filter((b) => b.text)
      return blocks;
    },
    directBeneficiaries() {
      if (this.campaignLandingPageConfig.directBeneficiariesValue) {
        return this.campaignLandingPageConfig.directBeneficiariesValue;
      } else {
        return this.campaignLandingPageConfig.campaign.directBeneficiaries;
      }
    },
    indirectBeneficiaries() {
      if (this.campaignLandingPageConfig.indirectBeneficiariesValue) {
        return this.campaignLandingPageConfig.indirectBeneficiariesValue;
      } else {
        return this.campaignLandingPageConfig.campaign.indirectBeneficiaries;
      }
    },
  },
  methods: {
    async getUserInformation() {
      this.entityId = this.$route.query.entityId
      if (this.logged) {
        await this.personService.getLoggedPersonUser(this.entityId).then((data) => {
          this.userInformation = data;
        })
      }
    },
    openModalAttributeOfferSubscribe(attributeOffer, isDeleted = false) {
      this.volunteeringAttributeOfferSelected = attributeOffer;
      this.infoCard = attributeOffer
      if (!this.logged) {
        this.$store.commit("SHOW_LOGIN");
        return;
      }

      if (isDeleted) this.isDeleted = true
      else this.isDeleted = false

      this.volunteeringRegistrationOfferService.findUserRegistrationOffersToAttributeOffer(attributeOffer.id).then(data => {
        for (const item of data) {
          if (!item.isDeleted) this.volunteeringUserRegistrationOffers.push(item)
        }

        this.volunteeringDialog = true;
      });
    },
    async userHasRegistrations () {
      let registrations = 0
      let registration = []
      let attributeOfferIds = []

      for (const attributeOffer of this.volunteeringAttributeOffers) {
        await this.volunteeringRegistrationOfferService.findUserRegistrationOffersToAttributeOffer(attributeOffer.id).then(data => {
          for (const item of data) {
            if (!item.isDeleted) {
              registration.push(item)
              registrations++
            }
          }
        });
      }

      for (const reg of registration) {
        if (reg.volunteeringRecurrentType) {
          attributeOfferIds.push(reg.volunteeringRecurrentType.volunteeringAttributeOfferId)
        }

        if (reg.volunteeringPontualType) {
          attributeOfferIds.push(reg.volunteeringPontualType.volunteeringAttributeOfferId)
        }
      }

      this.VolunteerSlot = attributeOfferIds
      if (registrations > 0) this.hasSubscribedOnVolunteerSlot = true
      else this.hasSubscribedOnVolunteerSlot = false
    },
    async loadVolunteering(){
      const userVolunteering = []
      let allVolunteering = []
      const idsSubscribed = []
      let volunteeringExists = []

      if (this.logged) {
        if (this.userInformation !== null) {
          await this.volunteeringRegistrationOfferService.findUserRegistrationOffersbyUserId(this.userInformation.userId).then(data => {
            for (const volunteer of data) {
              volunteer.userHasSubscription = true
              idsSubscribed.push(volunteer.id)
              userVolunteering.push(volunteer)
            }
          });
        }
        
        await this.volunteeringAttributeOfferService.listVolunteeringAttributeOfferByCampaign().then(async (data) => {
          for (const volunteer of data) {
            volunteer.userHasSubscription = false
            allVolunteering.push(volunteer)
          }
        })

        // Separe information cards that user already has subscription ans pass to another array
        for (const id of idsSubscribed) {
          let item = allVolunteering.filter((e) => {
            return e.id === id
          })

          if (volunteeringExists.indexOf(item[0]) === -1) volunteeringExists.push(item[0])
        }

        // Remove information cards from main array that user already has subcription
        for (const id of idsSubscribed) {
          allVolunteering = allVolunteering.filter((e) => {
            return e.id !== id
          })
        }

        // Add updated information to cards separated
        for (const volunteer of userVolunteering) {
          volunteeringExists.map((e) => {
            if (e.id === volunteer.id) {
              e.isDeleted = volunteer.isDeleted
              e.userHasSubscription = volunteer.userHasSubscription
            }
          })
        }
        allVolunteering = allVolunteering.concat(volunteeringExists) // Concat modified cards array to main array
      } else {
        await this.volunteeringAttributeOfferService.listVolunteeringAttributeOfferByCampaign().then(async (data) => {
          for (const volunteer of data) {
            volunteer.userHasSubscription = false
            allVolunteering.push(volunteer)
          }
        })
      }
      this.volunteeringAttributeOffers = allVolunteering
      this.getTotalOffers(this.volunteeringAttributeOffers)
      this.volunteeringAttributeOffersBackup = this.volunteeringAttributeOffers
      this.volunteerFilterCreate(this.volunteeringAttributeOffers)
      this.userAlreadyHasRegistrations = await this.userHasRegistrations()
    },
    volunteerFilterCreate (offers) {
      const volunteerFilterPlace = this.volunteerFilters
      for (const offer of offers) {
        const data = {
          city: offer.address.city,
          state: offer.address.state,
          isDistance: offer.isDistanceOportunityAllowed,
          isPresential: offer.isPresentialOportunityAllowed,
          institutionName: offer.volunteering.institution.entity.name,
        }

        if (volunteerFilterPlace.places.indexOf(data.city) === -1) volunteerFilterPlace.places.push(data.city)
        if (volunteerFilterPlace.names.indexOf(data.institutionName) === -1) volunteerFilterPlace.names.push(data.institutionName)

        this.volunteerData.push(data)
      }
    },
    async filterVolunteer (event) {
        const value = event.target.value
        const id = event.target.id

        if (this.volunteerFiltersIds.indexOf(id) === -1) {
          this.volunteerFiltersIds.push(id)
        }

        if (this.volunteerFiltersIds.indexOf(id) !== -1 && (value !== null || value !== "")) {
          if (id === 'selectPlace') {
            await this.clearFilters(id)
            this.volunteerFiltersSelected.places.push(value)
          }
          if (id === 'typeSubscription') {
            await this.clearFilters(id)
            this.volunteerFiltersSelected.types.push(value)
          }
          if (id === 'institutionName') {
            await this.clearFilters(id)
            this.volunteerFiltersSelected.names.push(value)
          }
        }

        if ((value === null || value === "") && this.volunteerFiltersIds.indexOf(id) !== -1) {
          this.volunteerFiltersIds.splice(this.volunteerFiltersIds.indexOf(id), 1)
          if (id === 'selectPlace') await this.clearFilters(id)
          if (id === 'typeSubscription') await this.clearFilters(id)
          if (id === 'institutionName') await this.clearFilters(id)
        }

        this.volunteeringAttributeOffers = this.volunteeringAttributeOffersBackup

        for (const id of this.volunteerFiltersIds) {
            if (id === 'selectPlace' && (value !== null || value !== '')) {
              this.volunteeringAttributeOffers = this.volunteeringAttributeOffers.filter((offer) => offer.address.city === this.volunteerFiltersSelected.places[0])
            }

            if (id === 'typeSubscription' && (value === 'Presencial')) {
              this.volunteeringAttributeOffers = this.volunteeringAttributeOffers.filter((offer) => offer.isPresentialOportunityAllowed === true)
            }

            if (id === 'typeSubscription' && (value === 'A Distância')) {
              this.volunteeringAttributeOffers = this.volunteeringAttributeOffers.filter((offer) => offer.isDistanceOportunityAllowed === true)
            }

            if (id === 'institutionName' && (value !== null || value !== '')) {
              this.volunteeringAttributeOffers = this.volunteeringAttributeOffers.filter((offer) => offer.volunteering.institution.entity.name === this.volunteerFiltersSelected.names[0])
            }
          }
    },
    async clearFilters (id) {
      if (id === 'selectPlace') this.volunteerFiltersSelected.places = []
      if (id === 'typeSubscription') this.volunteerFiltersSelected.types = []
      if (id === 'institutionName') this.volunteerFiltersSelected.names = []
    },
    getTotalOffers(offers) {
      this.totalFreeOffers = 0
      this.totalOffers = 0
      this.totalSubscribers = 0
      for (const offer of offers) {
        this.totalOffers += offer.totalOfferAmount
        this.totalSubscribers += offer.registrationAmount
      }
      this.totalFreeOffers = this.totalOffers - this.totalSubscribers
      this.offersPercentage = ((this.totalSubscribers * 100)/this.totalOffers)
    },
    getProjectTypeName(projectTypeId){
      this.checkProjectType = projectTypeId
      return GetProjectTypeName(projectTypeId);
    },
    ...mapActions(["addToCart", "setSimplifiedCheckout"]),
    slickOnScroll(e) {
      let x = e.target.scrollLeft;
      let next = e.target.parentElement.querySelector(".next");
      let prev = e.target.parentElement.querySelector(".prev");

      if (x <= 0) {
        if (prev) prev.style.display = "none";
      } else {
        if (prev) prev.style.display = "flex";
      }
      if (e.target.scrollLeft + e.target.offsetWidth === e.target.scrollWidth) {
        if (next) next.style.display = "none";
      } else {
        if (next) next.style.display = "flex";
      }
    },
    calculateDolar() {
      this.capturedValue = this.campaignLandingPageConfig.campaign.capturedValue;
      this.objetiveValue = this.campaignLandingPageConfig.campaign.objetiveDonationValue;

      if (this.campaignLandingPageConfig.campaign.allowUsdDonation) {
        this.coinSelected = "US$ "
        this.cartService.getDolarPrice().then(
          function (data) {
            this.dolarPrice = parseFloat(data.bid);
            this.capturedValue = this.capturedValue / this.dolarPrice;
            this.objetiveValue = this.objetiveValue / this.dolarPrice;
          }.bind(this)
        );
      }
      this.percentualValue = this.calculatePercentual(this.capturedValue, this.objetiveValue, 10, 90);
    },
    showModalTemplate() {
      this.showModalTemplate = false;
    },
    showModalChatBot() {
      this.showModalChatBot = true;
    },
    goUp(block) {
      let currentIndex =
        this.campaignLandingPageConfig.blockOrderArray.indexOf(block);
      if (currentIndex > 0) {
        this.campaignLandingPageConfig.blockOrderArray.splice(currentIndex, 1);
        this.campaignLandingPageConfig.blockOrderArray.splice(
          currentIndex - 1,
          0,
          block
        );
        this.save();
      }
    },
    goDown(block) {
      let currentIndex =
        this.campaignLandingPageConfig.blockOrderArray.indexOf(block);
      if (
        currentIndex <
        this.campaignLandingPageConfig.blockOrderArray.length - 1
      ) {
        this.campaignLandingPageConfig.blockOrderArray.splice(currentIndex, 1);
        this.campaignLandingPageConfig.blockOrderArray.splice(
          currentIndex + 1,
          0,
          block
        );
        this.save();
      }
    },
    verifyWindows() {
      return !navigator.platform.indexOf("Win");
    },
    openModalNews(text) {
      this.modalNewsObj = text;
      this.dialogNews = true;
    },
    openReadMoreTestimony(testimony, title) {
      this.$refs.readMoreTestimony.open(testimony, title);
    },
    closeModalNews() {
      this.dialogNews = false;
    },
    openModalEditor() {
      this.dialogEditor = true;
    },
    closeModalEditor() {
      this.dialogEditor = false;
    },
    applyInjections(value) {
      this.campaignLandingPageConfig.cssInjection = value[0];
      this.campaignLandingPageConfig.jsInjection = value[1];
      this.closeModalEditor();
      this.save();
    },
    openInfoDialog(projectType) {
      this.$refs.infoProject.open(projectType);
    },
    hideLocationBox(projectId) {
      var ref = this.$refs["locationBox" + projectId];
      if (ref) ref[0].classList.remove("active");
    },
    showLocationBox(projectId) {
      var ref = this.$refs["locationBox" + projectId];
      if (ref) ref[0].classList.add("active");
    },
    campaignService_calculateCampaignValuesCallback(data) {
      this.campaignLandingPageConfig.campaign.directBeneficiaries =
        data.directBeneficiaries;
      this.campaignLandingPageConfig.campaign.indirectBeneficiaries =
        data.indirectBeneficiaries;
      this.campaignLandingPageConfig.campaign.capturedValue =
        data.capturedValue;
      this.campaignLandingPageConfig.campaign.objetiveDonationValue =
        data.objetiveDonationValue;

      this.calculateDolar();
    },
    campaignService_getCampaignCaseListCallback(data) {
      this.campaignLandingPageConfig.campaignCaseList = data;
    },
    campaignService_getCampaignPhotoCardsListCallback(data){
      this.campaignLandingPageConfig.campaignPhotoCardsList = data;
    },
    campaignService_getCampaignDonationStepListCallback(data) {
      this.campaignLandingPageConfig.campaignDonationStepList = data;
    },
    campaignService_getCampaignAppreciationListCallback(data) {
      this.campaignLandingPageConfig.campaignAppreciationList = data;
    },
    campaignService_getCampaignImageListCallback(data) {
      this.campaignLandingPageConfig.campaignImageList = data;
    },
    campaignService_getCampaignNewsListCallback(data) {
      this.campaignLandingPageConfig.campaignNewsList = data;
    },
    campaignService_getCampaignPartnerListCallback(data) {
      this.campaignLandingPageConfig.campaignPartnerList = data;
    },
    campaignService_getCampaignTestimonyListCallback(data) {
      this.campaignLandingPageConfig.campaignTestimonyList = data;
    },
    campaignService_getCampaignProjectListCallback(data) {
      if (data.length >= 4 && (this.selectedCoverage || this.selectedCause))
        this.gotoProjectList(true);
      else {

        if (this.campaignLandingPageConfig.campaign.campaignTypeId != CampaignTypes.Voluntariado) {
          this.campaignLandingPageConfig.projectList = data;
          this.campaignLandingPageConfig.projectList.map(e=>{
            e.donationProductSelected = null;
            return e;
          })
          this.filteredProjects = this.campaignLandingPageConfig.projectList;
          return;
        }
        this.getVolunteeringInfo(data);
      }

      // this.fillCausesAndCoveragesList(
      //   this.campaignLandingPageConfig.projectList
      // );
      //this.buildBlocksAnchors();
      // this.searchProjects();
    },
    campaignService_getProjectCauseListCallback(data) {
      this.causeList = data;
    },
    campaignService_getProjectCoverageListCallback(data) {
      this.coverageList = data;
    },
    fillCausesAndCoveragesList(projectList) {
      this.causeList = projectList.map((project) => project.cause);
      this.causeList = Array.from(
        new Set(this.causeList.map((cause) => cause.id))
      ).map((causeId) => this.causeList.find((cause) => cause.id == causeId));

      // this.coverageList = projectList
      //   .map(project => project.coverageList)
      //   .flat();
      this.coverageList = flat(
        projectList.map((project) => project.coverageList)
      );
      this.coverageList = Array.from(
        new Set(this.coverageList.map((coverage) => coverage.name))
      ).map((coverageName) =>
        this.coverageList.find((coverage) => coverage.name == coverageName)
      );

      this.coverageList.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1;
        return 0;
      });
      this.causeList.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1;
        return 0;
      });
    },
    countProjectImage() {
      this.loadedProjectImageCount++;
      if (
        this.loadedProjectImageCount >=
        4 * this.campaignLandingPageConfig.projectList.length
      ) {
        this.reInitSlick(this.$refs.projectsSlick, true);
      }
    },
    projectsSlick_afterChange() {
      if (!this.projectsSlickReInitiated) {
        this.projectsSlickReInitiated = true;
        this.reInitSlick(this.$refs.projectsSlick, true);
      }
    },
    reInitSlick(slickComponent, gotoCurrentPage = false) {
      if (slickComponent == this.$refs.projectsSlick)
        if (slickComponent) {
          let currIndex = 0;
          if (gotoCurrentPage) currIndex = slickComponent.currentSlide();
          // slickComponent.destroy();
          this.$nextTick(() => {
            // slickComponent.create();
            if (gotoCurrentPage) slickComponent.goTo(currIndex, true);
          });
        }
    },
    searchProjects() {
      this.campaignLandingPageConfig.projectList = null;
      if (this.selectedCause == "") this.selectedCause = null;
      if (this.selectedCoverage == "") this.selectedCoverage = null;
      this.campaignService
        .getCampaignProjectList(
          this.campaignLandingPageConfig.campaign.id,
          this.howManyProjects,
          this.selectedCoverage,
          this.selectedCause
        )
        .then(this.campaignService_getCampaignProjectListCallback);
      this.campaignService
        .getProjectCauseList(
          this.campaignLandingPageConfig.campaign.id,
          this.selectedCoverage
        )
        .then(this.campaignService_getProjectCauseListCallback);
      this.campaignService
        .getProjectCoverageList(
          this.campaignLandingPageConfig.campaign.id,
          this.selectedCause
        )
        .then(this.campaignService_getProjectCoverageListCallback);
    },
    getVideoLink() {
      let videoHash = CommonHelper.validateYoutubeLink(
        this.campaignLandingPageConfig.videoLink
      );
      if (videoHash && videoHash.length == 11) {
        return "https://www.youtube.com/embed/" + videoHash;
      }
      return "";
    },
    donate() {

      if (this.campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Voluntariado) {
        let project = this.campaignLandingPageConfig.projectList[0]

        this.volunteeringService.findByProjectId(project.id).then(data => {

          this.$router.push({
            name: Routes.app.VolunteeringLP,
            query: { volunteeringId: data.id },
          });
        });
      }

      if (this.campaignLandingPageConfig.showSimplifiedDonationBlock) {
        this.scrollMeTo("simplifiedCheckout");
      } else if (
        this.campaignLandingPageConfig.projectList &&
        this.campaignLandingPageConfig.projectList.length == 1
      ) {
        if (
          this.campaignLandingPageConfig.projectList[0].donationProductList
            .length > 0
        )
          this.scrollMeTo("donationProductBlock");
        else {
          if (!this.ticketsAvailable) {
            this.addCartItem(this.campaignLandingPageConfig.projectList[0]);
            this.gotoCart();
          } else {
            CommonHelper.swal("Sem tickets disponíveis para compra.");
          }
          // this.openConfirmDonationToProjectModal(this.campaignLandingPageConfig.projectList[0]);
        }
      } else {
        this.scrollMeTo("projectsBlock");
      }
    },
    async reloadCards() {
      await this.loadVolunteering();
      this.volunteeringCardsKey += 1;
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      if (element && element[0]) {
        var topPosition = element[0].offsetTop;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
        return true;
      }
      return false;
    },
    goesTo() {
      const projectsElement = document.getElementById('projectsBlock')

      if (this.checkProjectType === this.ProjectTypes.Voluntariado) {
        const element = document.getElementById('volunteerBlock');
        element.scrollIntoView({behavior: 'smooth'}, true);
        return
      }

      if (projectsElement) {
        projectsElement.scrollIntoView({behavior: 'smooth'}, true);
        return
      }

      if (this.campaignLandingPageBlockIdentifier.SimplifiedDonationBlock) {
        const element = document.getElementById('simplifiedDonationBlock');
        element.scrollIntoView({behavior: 'smooth'}, true);
        return
      }    
    },
    openConfirmDonationToProjectModal(item) {
      if (item.donationProductList.length == 0) {
        this.addCartItem(item);
        document.getElementById("animation-proj").classList.add("active");
        setTimeout(() => {
          document.getElementById("animation-proj").classList.remove("active");
        }, 2000);

        if (!this.campaignLandingPageConfig.campaign.donateToMoreThanOneProduct) {
          this.gotoCart();
        }
        // this.$refs.confirmDonationComponent.open();
      } else {
        if (Array.isArray(this.$refs.listProductsDialog))
          this.$refs.listProductsDialog[0].open(item);
        else this.$refs.listProductsDialog.open(item);
      }
    },
    addCartItem(project) {
      var cartItem = {
        project: project,
      };
      this.addToCart(cartItem);
    },
    addCartItemWithDonationProduct(project, donationProduct){
      donationProduct.project = {};
      project.donationProductList = {};
      var cartItem = {
        project,
        donationProduct
      };
      this.addToCart(cartItem);
    },
    addProjectFromModalAppreciationDonationproduct(donationProduct){
      this.addCartItemWithDonationProduct(donationProduct.project, donationProduct);
      this.closeModalProjects();
      if (!this.campaignLandingPageConfig.campaign.donateToMoreThanOneProduct) {
          this.gotoCart();
      }
    },
    checkIfProjectAlreadyExistInTheCart(project) {
      if (project && project.donationProductList.length == 0)
        return this.alreadyExistsInCart({ project: project });
      return false;
    },
    gotoCart() {
      this.$router.push({ name: Routes.web.CartLogged });
    },
    gotoProjectLP(projectId) {
      this.$router.push({
        name: Routes.web.ProjectLP,
        query: { projectId: projectId },
      });
    },
    gotoCampaignList() {
      var entityId = this.$route.query.entityId;
      this.$router.push({
        name: Routes.app.CampaignList,
        query: { entityId: entityId },
      });
    },
    gotoProjectList(withFilter) {
      if (withFilter) {
        let customQuery = {};
        customQuery["campaignId"] = this.campaignLandingPageConfig.campaign.id;
        if (this.selectedCoverage)
          customQuery["coverage"] = this.selectedCoverage;
        if (this.selectedCause)
          customQuery["causeId"] = parseInt(this.selectedCause);

        this.$router.push({
          name: Routes.web.ProjectAll,
          query: Object.assign({}, customQuery),
        });
      } else {
        this.$router.push({
          name: Routes.web.ProjectAll,
          query: {
            campaignId: this.campaignLandingPageConfig.campaign.id,
          },
        });
      }
    },
    calculatePercentual(current, total, minimum = 0, maximum = 100) {
      let v = 0;
      if (total > 0) {
        v = Math.round((current / total) * 100);
        if (v < 100) v = Math.min(maximum, Math.max(minimum, v));
        else if (v > 100) v = 100;
      }
      return v + "%";
    },
    buildThemeColors() {
      let themes = [];
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themePrimaria, 0)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themeSecundaria, 1)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themeDestaque, 2)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themeFundo, 3)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themePadrao, 4)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themeGradienteStart, 5)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themeGradienteEnd, 6)
      );
      themes.push(
        this.createJSON(this.campaignLandingPageConfig.themeTexto, 7)
      );
      this.changeCSSVariable(
        "--font-principal",
        this.campaignLandingPageConfig.fontPrincipal,
        false
      );
      this.changeCSSVariable(
        "--font-destaques",
        this.campaignLandingPageConfig.fontDestaques,
        false
      );
      this.themeColors = themes;
    },
    createJSON(theme, index) {
      theme = JSON.parse(theme);
      this.changeCSSVariable(this.cssVariableNames[index], theme, false);
      return { r: theme[0], g: theme[1], b: theme[2], a: theme[3] };
    },
    changeCSSVariable(cssVariable, value, shouldSave = true) {
      let propValue = value;
      if (typeof value != "string") {
        propValue = JSON.stringify(value);
      }
      this.campaignLandingPageConfig[
        this.cssVariableToPropertyMapping[cssVariable]
      ] = propValue;
      if (cssVariable == "--font-principal") value += ", sans-serif";
      if (cssVariable == "--font-destaques") value += ", serif";
      document.documentElement.style.setProperty(cssVariable, value);
      if (shouldSave) this.save();
    },
    getSelectedCompaines(projectLandingPageConfig, onlyTwo) {
      var result = "";
      if (projectLandingPageConfig && projectLandingPageConfig.project) {
        var campaignProjectList =
          projectLandingPageConfig.project.campaignProjectList;
        if (campaignProjectList && campaignProjectList.length > 0) {
          for (let i = 0; i < campaignProjectList.length; i++) {
            var campaignProjectCompanyList =
              campaignProjectList[i].campaignProjectCompanyList;
            if (
              campaignProjectCompanyList &&
              campaignProjectCompanyList.length > 0
            ) {
              for (let j = 0; j < campaignProjectCompanyList.length; j++) {
                if (
                  campaignProjectCompanyList[j].company &&
                  campaignProjectCompanyList[j].company.entity
                ) {
                  if (result == "") {
                    result =
                      "Apoio: " +
                      campaignProjectCompanyList[j].company.entity.name;
                  } else if (onlyTwo) {
                    if (j < 2)
                      result +=
                        ", " +
                        campaignProjectCompanyList[j].company.entity.name;
                    else {
                      result += ", ...";
                      j = campaignProjectCompanyList.length;
                    }
                  } else {
                    result +=
                      ", " + campaignProjectCompanyList[j].company.entity.name;
                  }
                }
              }
            }
          }
        }
      }
      return result;
    },
    getCoverageName(coverageList) {
      if (coverageList.length == 0) return "";
      else if (coverageList.length == 1) return coverageList[0].name;
      else {
        return coverageList[0].name + " + " + (coverageList.length - 1);
      }
    },
    updateProperty(property, event) {
      if (this.campaignLandingPageConfig[property] != event.target.innerHTML) {
        this.campaignLandingPageConfig[property] = event.target.innerHTML;
        // console.log(
        //   property,
        //   this.campaignLandingPageConfig[property],
        //   event.target.innerHTML
        // );
        this.save();
      }
    },
    allowOnlyNumber(event) {
      let current = event.target.innerHTML;
      var regex = /^[0-9]|.*$/;
      if (!regex.test(String(current))) {
        event.target.innerHTML = event.target.innerHTML.replace(/\D/g, "");
      }
    },
    updatePropertyValue(property, event) {
      if (this.campaignLandingPageConfig[property] != event.target.value) {
        this.campaignLandingPageConfig[property] = event.target.value;
        this.save();
      }
    },
    updateBeneficiariesProperty(property, event) {
      if (this.campaignLandingPageConfig[property] != event.target.innerHTML) {
        this.campaignLandingPageConfig[property] = Number(
          event.target.innerHTML.replace(/\./g, "")
        );
        this.save();
      }
    },
    openGalleryLightbox(index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (
        this.campaignLandingPageConfig == null ||
        this.campaignLandingPageConfig.campaignImageList == null
      )
        return [];
      for (
        let i = 0;
        i < this.campaignLandingPageConfig.campaignImageList.length;
        i++
      ) {
        if (
          this.campaignLandingPageConfig.campaignImageList[i] != null &&
          this.campaignLandingPageConfig.campaignImageList[i].file != null
        )
          imgs.push(
            this.campaignLandingPageConfig.campaignImageList[i].file.path
          );
      }
      return imgs;
    },
    toggleHiddenClass(property) {
      let error = this.checkShowSimplifiedDonationBlock(property);
      if (error) return;
      this.campaignLandingPageConfig[property] =
        !this.campaignLandingPageConfig[property];
      if (this.campaignLandingPageConfig.campaign.campaignTypeId != CampaignTypes.Voluntariado) {
        if (property == "showSimplifiedDonationBlock") {
          this.campaignLandingPageConfig.showProjectsBlock =
            !this.campaignLandingPageConfig.showSimplifiedDonationBlock;
        }
        if (property == "showProjectsBlock") {
          this.campaignLandingPageConfig.showSimplifiedDonationBlock =
            !this.campaignLandingPageConfig.showProjectsBlock;
        }
      }
      //this.buildBlocksAnchors();
      this.save();
    },
    checkShowSimplifiedDonationBlock(property) {
      if (property != "showSimplifiedDonationBlock") return false;
      let paymentMethods =
        this.campaignLandingPageConfig.campaign.paymentMethodList;
      paymentMethods = [...new Set(paymentMethods)];
      /*if (paymentMethods.includes(PaymentMethod.AccountDebit)) {
        CommonHelper.swal(
          "O Checkout simplificado não pode ser habilitado, pois a campanha possui o débito em conta ativo."
        );
        return true;
      } else */
      if (paymentMethods.includes(PaymentMethod.PayrollDeduction)) {
        CommonHelper.swal(
          "A doação simplificada não pode ser habilitado, pois a campanha possui o débito em folha ativo."
        );
        return true;
      }
      return false;
    },
    changeHeroAlignment() {
      this.campaignLandingPageConfig.heroAlignment =
        (this.campaignLandingPageConfig.heroAlignment + 1) % 3;
      this.save();
    },
    toggleHeroPreview() {
      this.heroPreview = !this.heroPreview;
    },
    toggleHeroImageUpload() {
      this.heroUploadImage = !this.heroUploadImage;
    },
    toggleSimulationImageUpload() {
      this.simulationUploadImage = !this.simulationUploadImage;
    },
    toggleMemberGetMemberImageUpload() {
      this.memberGetMemberUploadImage = !this.memberGetMemberUploadImage;
    },
    toggleSimplifiedDonationImageUpload() {
      this.simplifiedDonationUploadImage = !this.simplifiedDonationUploadImage;
    },
    toggleVideoImageUpload() {
      this.videoUploadImage = !this.videoUploadImage;
    },
    toggleWatchVideo() {
      this.watchVideo = !this.watchVideo;
    },
    toggleTestimonyImageUpload() {
      this.testimonyUploadImage = !this.testimonyUploadImage;
    },
    toggleHeroImageOpacity() {
      this.showHeroOverlayOpacitySlider = false;
      this.showHeroImageOpacitySlider = !this.showHeroImageOpacitySlider;
    },
    toggleHeroOverlayOpacity() {
      this.showHeroImageOpacitySlider = false;
      this.showHeroOverlayOpacitySlider = !this.showHeroOverlayOpacitySlider;
    },
    toggleSimulationImageOpacity() {
      this.showSimulationOverlayOpacitySlider = false;
      this.showSimulationImageOpacitySlider =
        !this.showSimulationImageOpacitySlider;
    },
    toggleMemberGetMemberImageOpacity() {
      this.showMemberGetMemberOverlayOpacitySlider = false;
      this.showMemberGetMemberImageOpacitySlider =
        !this.showMemberGetMemberImageOpacitySlider;
    },
    toggleSimulationOverlayOpacity() {
      this.showSimulationImageOpacitySlider = false;
      this.showSimulationOverlayOpacitySlider =
        !this.showSimulationOverlayOpacitySlider;
    },
    toggleMemberGetMemberOverlayOpacity() {
      this.showMemberGetMemberImageOpacitySlider = false;
      this.showMemberGetMemberOverlayOpacitySlider =
        !this.showMemberGetMemberOverlayOpacitySlider;
    },
    toggleSimplifiedDonationImageOpacity() {
      this.showSimplifiedDonationOverlayOpacitySlider = false;
      this.showSimplifiedDonationImageOpacitySlider =
        !this.showSimplifiedDonationImageOpacitySlider;
    },
    toggleSimplifiedDonationOverlayOpacity() {
      this.showSimplifiedDonationImageOpacitySlider = false;
      this.showSimplifiedDonationOverlayOpacitySlider =
        !this.showSimplifiedDonationOverlayOpacitySlider;
    },
    toggleVideoImageOpacity() {
      this.showVideoOverlayOpacitySlider = false;
      this.showVideoImageOpacitySlider = !this.showVideoImageOpacitySlider;
    },
    toggleVideoOverlayOpacity() {
      this.showVideoImageOpacitySlider = false;
      this.showVideoOverlayOpacitySlider = !this.showVideoOverlayOpacitySlider;
    },
    toggleTestimonyImageOpacity() {
      this.showTestimonyOverlayOpacitySlider = false;
      this.showTestimonyImageOpacitySlider =
        !this.showTestimonyImageOpacitySlider;
    },
    toggleTestimonyOverlayOpacity() {
      this.showTestimonyImageOpacitySlider = false;
      this.showTestimonyOverlayOpacitySlider =
        !this.showTestimonyOverlayOpacitySlider;
    },
    imageChanged(event, fo, ic, tf) {
      this.fileObject = fo;
      if (ic instanceof Array) this.imageComponent = ic[0];
      else this.imageComponent = ic;
      this.toggleFunction = tf;
      var file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function () {
        if (
          img.width < 1600 ||
          img.height < 800 ||
          img.width > 2880 ||
          img.height > 1300
        ) {
          CommonHelper.swal(
            "Tamanho para upload da imagem deve ser entre 1600x800px e 2880x1300px. Sua imagem possui dimensões " +
            img.width +
            "x" +
            img.height
          );
        } else {
          new ImageCompressor(file, {
            convertSize: 409600,
            success: function (result) {
              let reader = new FileReader();
              this.fileObject.name = result.name;
              reader.onload = function () {
                this.imageComponent.style.backgroundImage =
                  "url(" + reader.result + ")";
                this.fileObject.path = reader.result;
                this.fileObject.imageContent = reader.result;
                this.toggleFunction();
                this.save();
              }.bind(this);
              reader.readAsDataURL(result);
            }.bind(this),
          });
        }
      }.bind(this);
    },
    heroImageChanged(event) {
      this.imageChanged(
        event,
        this.campaignLandingPageConfig.heroFile,
        this.$refs.heroImage,
        this.toggleHeroImageUpload
      );
    },
    simulationImageChanged(event) {
      this.imageChanged(
        event,
        this.campaignLandingPageConfig.simulationFile,
        this.$refs.simulationImage,
        this.toggleSimulationImageUpload
      );
    },
    memberGetMemberImageChanged(event) {
      this.imageChanged(
        event,
        this.campaignLandingPageConfig.memberGetMemberFile,
        this.$refs.memberGetMemberImage,
        this.toggleMemberGetMemberImageUpload
      );
    },
    simplifiedDonationImageChanged(event) {
      this.imageChanged(
        event,
        this.campaignLandingPageConfig.simplifiedDonationFile,
        this.$refs.simplifiedDonationImage,
        this.toggleSimplifiedDonationImageUpload
      );
    },
    videoImageChanged(event) {
      this.imageChanged(
        event,
        this.campaignLandingPageConfig.videoFile,
        this.$refs.videoImage,
        this.toggleVideoImageUpload
      );
    },
    testimonyImageChanged(event) {
      this.imageChanged(
        event,
        this.campaignLandingPageConfig.testimonyFile,
        this.$refs.testimonyImage,
        this.toggleTestimonyImageUpload
      );
    },
    getCompanyImage() {
      if (
        this.campaignLandingPageConfig.campaign != null &&
        this.campaignLandingPageConfig.campaign.company != null &&
        this.campaignLandingPageConfig.campaign.company.file != null
      ) {
        //return campaignLandingPageConfig.campaign.company.file.path;
        if (
          this.campaignLandingPageConfig.campaign.company.file.hasOwnProperty(
            "imageContent"
          )
        ) {
          // this.$set(
          //   this.landingPageOptions,
          //   "logo",
          //   "url(" +
          //     this.campaignLandingPageConfig.campaign.company.file
          //       .imageContent +
          //     ")"
          // );
          return this.campaignLandingPageConfig.campaign.company.file
            .imageContent;
        } else if (!this.loadingCompanyImage) {
          this.loadingCompanyImage = true;
          this.fileService
            .render(this.campaignLandingPageConfig.campaign.company.file.id)
            .then(
              function (data) {
                this.$set(
                  this.campaignLandingPageConfig.campaign.company.file,
                  "imageContent",
                  "url(" + data + ")"
                );
                // this.$set(this.landingPageOptions, "logo", this.campaignLandingPageConfig.campaign.company.file);
                //this.loadingCompanyImage = false;
              }.bind(this)
            );
        }
      }
      return "";
    },
    getInstitutionImage(project) {
      if (
        project.institution.file != null &&
        project.institution.file.hasOwnProperty("imageContent")
      ) {
        return project.institution.file.imageContent;
      } else if (!this.loadingInstitutionImage) {
        this.loadingInstitutionImage = true;
        this.fileService.render(project.institution.file.id).then(
          function (data) {
            this.$set(project.institution.file, "imageContent", data);
            this.loadingInstitutionImage = false;
          }.bind(this)
        );
      }
      return "";
    },
    getImage(propertyName, callback) {
      /* if (this.campaignLandingPageConfig[propertyName])
        return this.campaignLandingPageConfig[propertyName].path;
      return "";*/

      let configuration = this.campaignLandingPageConfig[propertyName];

      let configurationId = propertyName == "simplifiedDonationFile"
        ? this.campaignLandingPageConfig.simplifiedDonationFileId
        : configuration.id;

      if (!configuration.imageContent || configuration.imageContent == "")
        this.fileService
          .render(configurationId)
          .then(callback);
      else
        return `url(${configuration.imageContent})`;

    },
    fileService_renderHeroFileCallback(data) {
      this.$set(this.campaignLandingPageConfig.heroFile, "imageContent", data);
    },
    fileService_renderSimulationFileCallback(data) {
      this.$set(
        this.campaignLandingPageConfig.simulationFile,
        "imageContent",
        data
      );
    },
    fileService_renderMemberGetMemberFileCallback(data) {
      debugger
      this.$set(
        this.campaignLandingPageConfig.memberGetMemberFile,
        "imageContent",
        data
      );
    },
    fileService_renderSimplifiedDonationFileCallback(data) {
      this.$set(
        this.campaignLandingPageConfig.simplifiedDonationFile,
        "imageContent",
        data
      );
    },
    fileService_renderVideoFileCallback(data) {
      this.$set(this.campaignLandingPageConfig.videoFile, "imageContent", data);
    },
    fileService_renderTestimonyFileCallback(data) {
      this.$set(
        this.campaignLandingPageConfig.testimonyFile,
        "imageContent",
        data
      );
    },
    save() {
      if (this.editing) {
        if (!this.saving && this.saveQueue.length == 0) {
          this.saving = true;
          this.campaignLandingPageConfig.blockOrder = JSON.stringify(
            this.campaignLandingPageConfig.blockOrderArray
          );
          this.campaignService
            .saveLandingPageConfig(this.campaignLandingPageConfig)
            .then(this.campaignService_saveLandingPageConfigCallback)
            .catch(this.campaignService_saveLandingPageConfigError);
        } else {
          this.saveQueue.push(this.campaignLandingPageConfig);
        }
      }
    },
    campaignService_saveLandingPageConfigCallback(data) {
      this.campaignLandingPageConfig.id = data.id;

      let imageContent = this.campaignLandingPageConfig.heroFile.imageContent;
      this.campaignLandingPageConfig.heroFile = data.heroFile;
      this.campaignLandingPageConfig.heroFile.imageContent = imageContent;

      imageContent = this.campaignLandingPageConfig.simulationFile.imageContent;
      this.campaignLandingPageConfig.simulationFile = data.simulationFile;
      this.campaignLandingPageConfig.simulationFile.imageContent = imageContent;

      imageContent = this.campaignLandingPageConfig.videoFile.imageContent;
      this.campaignLandingPageConfig.videoFile = data.videoFile;
      this.campaignLandingPageConfig.videoFile.imageContent = imageContent;

      imageContent = this.campaignLandingPageConfig.testimonyFile.imageContent;
      this.campaignLandingPageConfig.testimonyFile = data.testimonyFile;
      this.campaignLandingPageConfig.testimonyFile.imageContent = imageContent;

      imageContent =
        this.campaignLandingPageConfig.simplifiedDonationFile.imageContent;
      this.campaignLandingPageConfig.simplifiedDonationFile =
        data.simplifiedDonationFile;
      this.campaignLandingPageConfig.simplifiedDonationFile.imageContent =
        imageContent;

      if (this.saveQueue.length != 0) {
        let clpc = this.saveQueue.shift();
        while (this.saveQueue.length > 0) clpc = this.saveQueue.shift();
        clpc.id = data.id;
        this.campaignService
          .saveLandingPageConfig(clpc)
          .then(this.campaignService_saveLandingPageConfigCallback)
          .catch(this.campaignService_saveLandingPageConfigError);
      } else {
        this.saving = false;
      }
    },
    campaignService_saveLandingPageConfigError() {
      this.saveQueue = [];
      this.saving = false;
    },
    gaProject(project) {
      var value = project.totalDonatedValueWithCapturedValue;
      return ga("send", "event", "Quero Doar", project.shortName, value);
    },
    getYear() {
      return new Date().getFullYear();
    },
    shuffle(array) {
      array.sort(() => Math.random() - 0.5);
      return array;
    },
    urlHashsActions() {
      switch (this.$route.query.open) {
        case "simulador":
          this.landingPageOptions.simulationDrawer = true;
          setTimeout(() => {
            var el = document.querySelector(".simulation-father");
            el.scrollTop = 0;
          }, 200);
          break;
        case "doe":
          this.scrollMeTo("heroBlock");
          break;
        case "agradecimentos":
          this.scrollMeTo("appreciationBlock");
          break;
        case "projetos":
          this.scrollMeTo("projectsBlock");
          break;
        case "faqs":
          document.getElementById("showFaq").click();
          break;
        case "suporte":
          document.getElementById("showContact").click();
          break;
        case "guiadedeclaracao":
          break;
        default:
          break;
      }
    },
    getHeroFile(volunteering){
      if(volunteering){
        const volunteeringFile = volunteering.volunteeringFiles.find(volunteeringFile => volunteeringFile.isHero);
        if(volunteeringFile)
          return volunteeringFile.file;
      }

      return null;
    },
    getVolunteeringInfo(projects, index = 0){
      if(projects.length == index){
        this.campaignLandingPageConfig.projectList = projects;
        this.filteredProjects = this.campaignLandingPageConfig.projectList;
        return;
      }

      const project = projects[index];
      this.volunteeringService.findByProjectId(project.id).then(data => {
        project.volunteering = data;
        project.volunteeringHeroFile = this.getHeroFile(data);
        this.getVolunteeringInfo(projects, index + 1);
      });
    },
    openModalProjects(appreciation){
      this.campaignAppreciationDonationProductService.listAllByCampaignAppreciationId(appreciation.id).then((data)=>{
        if(data.length == 0){
          this.donate();
          return;
        }
        const donationProducts = data.map(x => x.donationProduct)
        donationProducts.map(x => x.project = this.campaignLandingPageConfig.projectList.find(y => y.id == x.projectId));
        this.campaignAppreciationDonationproducts = donationProducts;
        this.appreciationSelected = appreciation
        this.dialogProjects = true;
      })
    },
    closeModalProjects(){
      this.appreciationSelected = null;
      this.dialogProjects = false;
    },
     soldOut(donationProduct) {
      return (
        this.project.projectTypeId == ProjectTypes.Ticket && !donationProduct.openTickets &&
        donationProduct.ticketsAvailableForSale <= 0
      );
    },
    openConfirmDonationToDonationProductModal(item) {
      this.addCartItem(this.project, item);
      if (this.isModal) {
        this.$emit("close");
      }
      document.getElementById("animation-proj").classList.add("active");
      setTimeout(() => {
        document.getElementById("animation-proj").classList.remove("active");
      }, 2000);

      if (!this.campaignLandingPage.campaign.donateToMoreThanOneProduct) {
        this.$router.push({ name: Routes.web.CartLogged });
      }
    },
  },
};
</script>
